import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
    InputGroup,
    Form,
    Modal
} from "reactstrap"
import ToastMessage from "components/Common/toastar";
import API from '../../api';
import Flatpickr from "react-flatpickr"
import { convertErrorMessage } from "components/Common/converErrorMessage";
import { PaymentType } from "common/list";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

const PaymentResponse = props => {

    let { id } = useParams()
    let [token_id, setTokenId] = useState();
    let [paymentAction, setPaymentAction] = useState();

    useEffect(() => {
        setTokenId(id);
        token_id = id;
        if (token_id) {
            checkValidToken();
        }
    }, []);

    function checkValidToken() {
        API.get('online_status_check/' + token_id).then(res => {
            setPaymentAction(res.data.data);
        }).catch(error => {

        });
    }

    function Print() {
        var divContents = document.getElementById("printablediv").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write(divContents);
        a.document.close();
        a.print();
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid mt-5">
                    <div className="d-flex content-flex-right">
                        <Button type="button" className="text-right" color="primary" onClick={() => { Print() }}>Print</Button>
                    </div>
                    <Card>

                        <CardBody>
                            <div id="printablediv">
                                <div dangerouslySetInnerHTML={{ __html: paymentAction }} />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment >
    );
}


export default PaymentResponse
