import React, { useState } from "react"
import ReactDOM from "react-dom";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
    InputGroup,
    Form,
    Modal
} from "reactstrap"
import ToastMessage from "components/Common/toastar";
import API from '../../api';
import Flatpickr from "react-flatpickr"
import { convertErrorMessage } from "components/Common/converErrorMessage";
import { PaymentType } from "common/list";


function AddPayment({ onCloseData }) {
    let [modal_fullscreen, setmodal_fullscreen] = useState(false)
    let [modal_xlarge, setmodal_xlarge] = useState(false)
    let [formData, setFormData] = useState({});
    let [formPaymentInformation, setFormPaymentInformation] = useState({
        payment_receipt: '',
        amount: '',
        payment_date: '',
        payment_note: '',
        payment_type: PaymentType[0].value
    });

    let [errorFormPaymentFormData, errorSetFormPaymentInformation] = useState({
        payment_receipt: null,
        amount: null,
        payment_date: null,
        payment_note: null,
        payment_type: null
    });
    let [typeofPayment, setTypeofPayment] = useState({});
    let [onlinePaymentAmounnt, setOnlinePaymentAmount] = useState({});
    let [imgData, setImgData] = useState(null);

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        removeBodyCss()
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_xlarge() {
        setmodal_xlarge(!modal_xlarge)
        removeBodyCss()
    }

    function validatePaymentForm(name, value) {
        if (name === 'payment_receipt' || name === 'payment_type' || name === 'amount' || name === 'payment_date' || name === 'payment_note') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                if (name === 'amount') {
                    switch (name) {
                        case "amount":
                            if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                                return "Enter a valid amount";
                            } else {
                                return "";
                            }
                        default: {
                            return "";
                        }
                    }
                } else {
                    return "";
                }
            }
        }
    }

    const updatePaymentFormData = event => {
        setFormPaymentInformation({
            ...formPaymentInformation,
            [event.target.name]: event.target.value
        });
        errorSetFormPaymentInformation({
            ...errorFormPaymentFormData,
            [event.target.name]: validatePaymentForm(event.target.name, event.target.value)
        });
    }

    function convertDate(date) {
        if (date) {
            var parts = date.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    }

    function findMemeberFromID() {
        const memberid = document.getElementById('member_id');
        // setMember_id(memberid.value)
        // member_id = memberid.value;
        if (memberid.value)
            getSingleMemberDetail(memberid.value);
    }

    function getSingleMemberDetail(id) {
        let data = { member_id: id }
        API.post('single_member', data).then(res => {
            if (res.data.status === 1) {
                let data = res.data.data.member;
                if (data) {
                    setFormData(data);
                } else {
                    ToastMessage({ type: 'error', message: 'Memeber details not given' })
                }
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }


    function handlePaymentSubmit(e) {
        e.preventDefault()

        if (!formData || !formData.member_id) {
            ToastMessage({ type: 'error', message: 'Please select member' });
            return;
        }
        let validationErrors = {};
        // formPaymentInformation.amount = formData.plan_info.plans_amount;

        Object.keys(formPaymentInformation).forEach(name => {
            const error = validatePaymentForm(name, formPaymentInformation[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            errorSetFormPaymentInformation(validationErrors);
            return;
        }

        var bodyFormData = new FormData();
        bodyFormData.append('payment_receipt', formPaymentInformation.payment_receipt);
        bodyFormData.append('amount', formPaymentInformation.amount);
        bodyFormData.append('payment_type', formPaymentInformation.payment_type);
        bodyFormData.append('payment_date', formPaymentInformation.payment_date);
        bodyFormData.append('payment_note', formPaymentInformation.payment_note);

        API.post('member_add_payment/' + formData.member_id, bodyFormData).then(res => {
            if (res.data.status === 1) {
                resetDonationForm();
                setTimeout(() => {
                    onCloseData(false);
                }, 1000)
                setmodal_xlarge(false);
                ToastMessage({ type: 'success', message: res.data.message })
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });

    }

    function uploadPaymentReceipt(event) {
        setImgData(null);
        imgData = null;
        if (validateImageUpload(event)) {
            if (event.target.files[0]) {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData(reader.result);
                    imgData = reader.result;
                });
                reader.readAsDataURL(event.target.files[0]);
            }
            formPaymentInformation.payment_receipt = event.target.files[0];
            setFormPaymentInformation({
                ...formPaymentInformation,
                payment_receipt: event.target.files[0]
            })
            errorSetFormPaymentInformation({
                ...errorFormPaymentFormData,
                'payment_receipt': validatePaymentForm('payment_receipt', event.target.files[0].name)
            });
            ToastMessage({ type: 'success', message: 'Image added' })
        }
    }

    function validateImageUpload(image) {
        var filePath = image.target.value;
        // Allowing file type
        var allowedExtensions =
            /(\.jpg|\.jpeg|\.png)$/i;

        if (!allowedExtensions.exec(filePath)) {
            ToastMessage({ type: 'error', message: 'Invalid file type' })
            image.value = '';
            return false;
        } else {
            return true;
        }
    }

    function handleOnlinePaymentSubmit(e) {
        e.preventDefault()
        if (!formData || !formData.member_id) {
            ToastMessage({ type: 'error', message: 'Please select member' });
            return;
        }
        if (!onlinePaymentAmounnt) {
            ToastMessage({ type: 'error', message: 'Please add valid amount' })
            return;
        }
        let validationErrors = {};

        let data = {
            "member_id": formData.member_id,
            "amount": onlinePaymentAmounnt
        }

        API.post('online_pay/', data).then(res => {
            if (res.data.status === 1) {
                window.open(res.data.pay_url, "_blank")
                // ToastMessage({ type: 'success', message: res.data.message });
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    function resetDonationForm() {
        formData = {};
        formPaymentInformation = {};
        setFormData({});
        setFormPaymentInformation({})
    }

    const updatePaymentDateSubmitForm = e => {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        setFormPaymentInformation({
            ...formPaymentInformation,
            payment_date: day + '-' + month + '-' + year,
        });
        errorSetFormPaymentInformation({
            ...errorFormPaymentFormData,
            'payment_date': validatePaymentForm('payment_date', year)
        });
    }

    return (
        <div className="App">
            <button type="button" onClick={() => { tog_xlarge() }}
                className="btn btn-primary waves-effect waves-light"
                data-toggle="modal"
                data-target=".bs-example-modal-xl"
            >Add Contribution</button>
            <Modal size="lg" isOpen={modal_xlarge} toggle={() => { tog_fullscreen() }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">Add Contribution </h5>
                    <button onClick={() => { resetDonationForm(); setmodal_xlarge(false);setImgData(null) }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12 mb-3 ">
                        <div className="mb-3">
                            <label className="control-label">Find Member</label>
                            <div className="d-flex w-100">
                                <div className="col-md-4 mb-3 mr-4 margin-btn">
                                    <input name="name" className="form-control" id="member_id" maxLength="50" label="Member ID" placeholder="Member ID" type="text" />
                                </div>

                                <div>
                                    <Button type="submit" color="primary" className="mb-3  margin-btn" onClick={() => findMemeberFromID()}>Find Member</Button>
                                </div>

                                {formData && formData.plan_info ?
                                    <div className="col-md-2">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">{formData.name}</label>
                                            {/* <div>{formData.name}</div> */}
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>

                    {formData && formData.plan_info ?
                        <div className="row">
                            {formData.plan_info.plans_id ?
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Plan Name</label>
                                            <div>{formData.plan_info.plan_name}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Amount</label>
                                            <div>{formData.plan_info.plans_amount}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label"> Type</label>
                                            <div>{formData.plan_info.plans_payment_type}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Approx Year</label>
                                            <div>{formData.plan_info.approx_year_payment}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Valid Age</label>
                                            <div>{formData.plan_info.plans_validity_age}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Max Age</label>
                                            <div>{formData.plan_info.plans_age_max}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="mb-3 templating-select select2-container">
                                            <label className="control-label">Min Age</label>
                                            <div>{formData.plan_info.plans_age_min}</div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                        : ""}

                    <div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label className="control-label">Contribution Type<span className="text-danger">*</span></label>
                                <select className="form-control" name="typeofPayment" value={typeofPayment} onChange={e => setTypeofPayment(e.target.value)}>
                                    <option key="0" value="online">Online</option>
                                    <option key="1" value="offline">Offline</option>
                                </select>
                            </div>
                        </div>
                        {typeofPayment === 'offline' ?

                            <form onSubmit={e => { handlePaymentSubmit(e) }}>
                                <div className="row mb-3">
                                    <div className="col-md-8">
                                        <div className="formbold-mb-5 formbold-file-input">
                                            <label>
                                                <input type="file" accept="image/png,image/jpg,image/jpeg" name="payment_receipt" onChange={e => uploadPaymentReceipt(e)} name="file" id="file" />
                                                <div>
                                                    <span className="formbold-drop-file"> Receipt File here </span>
                                                    <span className="formbold-or"> Or </span>
                                                    <span className="formbold-browse"> Browse </span>
                                                </div>
                                            </label>
                                            <span className="text-danger">{errorFormPaymentFormData.payment_receipt}</span>
                                        </div>
                                    </div>
                                    {imgData ?
                                        <div className="col-md-4">
                                            <div className="formbold-mb-5 formbold-file-input">
                                                <img className="playerProfilePic_home_tile" height="150" width="150" src={imgData} />

                                            </div>
                                        </div>
                                        : ""}
                                </div>
                                <div className="row mb-3">
                                    {/* <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="control-label">Amount</label>
                                        <input className="form-control" disabled name="amount" value={formData.plan_info.plans_amount} placeholder="Amount" type="number" />
                                        <span className="text-danger">{errorFormPaymentFormData.amount}</span>
                                    </div>
                                </div> */}

                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <Label>Payment Date</Label>
                                            <InputGroup>
                                                <Flatpickr value={convertDate(formPaymentInformation.payment_date)} className="form-control d-block" placeholder="dd M,yyyy" name="payment_date"
                                                    onChange={e => updatePaymentDateSubmitForm(e)}
                                                    onBlur={e => updatePaymentDateSubmitForm(e)}
                                                    options={{ altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d" }} />
                                            </InputGroup>
                                            <span className="text-danger">{errorFormPaymentFormData.payment_date}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label className="control-label">Amount</label>
                                            <input className="form-control" name="amount" value={formPaymentInformation.amount} placeholder="Payment Amount" type="text" onChange={e => updatePaymentFormData(e)}
                                                onBlur={e => updatePaymentFormData(e)} />
                                            <span className="text-danger">{errorFormPaymentFormData.amount}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label className="control-label">Contribution Type</label>
                                            <select className="form-control" name="payment_type" value={formPaymentInformation.payment_type} onChange={e => updatePaymentFormData(e)}>
                                                {PaymentType.map((res, i) => {
                                                    return <option key={i} value={res.value}>{res.label}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label className="control-label">Contribution Note</label>
                                            <input className="form-control" name="payment_note" value={formPaymentInformation.payment_note} placeholder="Payment Note" type="text" onChange={e => updatePaymentFormData(e)}
                                                onBlur={e => updatePaymentFormData(e)} />
                                            <span className="text-danger">{errorFormPaymentFormData.payment_note}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-end gap-2">
                                    <Button type="submit" color="primary" className="">Add Payment</Button>
                                    <Button type="button" color="secondary" onClick={() => { resetDonationForm(); setmodal_xlarge(false) }} className="">Close</Button>
                                </div>
                            </form>
                            : <form onSubmit={e => { handleOnlinePaymentSubmit(e) }}>

                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label className="control-label">Amount<span className="text-danger">*</span></label>
                                            <input className="form-control" name="amount" value={onlinePaymentAmounnt}
                                                onChange={e => setOnlinePaymentAmount(e.target.value)}
                                                onBlur={e => setOnlinePaymentAmount(e.target.value)} placeholder="Amount" type="number" />
                                        </div>
                                    </div>
                                </div>


                                <div className="d-flex flex-wrap gap-2">
                                    <Button type="submit" color="danger" className="">Pay Online</Button>
                                    <Button type="button" color="secondary" onClick={() => assignSelectedIndex(2)} className="">Back</Button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </Modal>

        </div >
    );
}
export default AddPayment;
