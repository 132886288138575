import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import Lightbox from "react-image-lightbox"
import ReceiptPDF from "pages/Member/ReceiptPDF";
import ToastMessage from "components/Common/toastar";
import SweetAlert from "react-bootstrap-sweetalert"
import API from '../../api';

const DocumentList = ({ document, divangat_id }) => {
    let [imagePreview, setImagePreview] = useState({
        currentAttachment: null,
        isFits: false
    })
    let [deleteDocument, setDeleteDocument] = useState({
        documentSelected: null,
        isFits: false
    })

    let [documentList, setDocumentList] = useState()


    function assignPhotostate(imageUrl, folder_name) {
        setImagePreview({
            isFits: true,
            currentAttachment: process.env.REACT_APP_IMAGEAPI + folder_name + '/' + imageUrl
        })
    }
    function removePhotostate() {
        setImagePreview({
            isFits: false,
            currentAttachment: null
        })
    }


    function assignDocumentSelected(document) {
        deleteDocument.documentSelected = document;
        setDeleteDocument({ isFits: true, documentSelected: document })
    }


    function removeDocumentSelected() {
        let data = {
            divangat_id: divangat_id,
            document_id: deleteDocument.documentSelected.document_id
        }
        API.post('deleted_divangat_document', data).then(res => {
            if (res.data.status === 1) {
                let data = res.data.data;
                if (documentList.length > 1) {
                    const updatedList = documentList.filter(res => res.id != deleteDocument.documentSelected.id);
                    setDocumentList(updatedList)
                } else {
                    setDocumentList([])
                }
                ToastMessage({ type: 'success', message: res.data.message })
                deleteDocument.documentSelected = null;
                setDeleteDocument({ isFits: false, documentSelected: null })
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    useEffect(() => {
        setDocumentList(document)
    }, [])

    return (
        <React.Fragment>
            {imagePreview.isFits ? (
                <Lightbox
                    mainSrc={imagePreview.currentAttachment}
                    enableZoom={false}
                    onCloseRequest={() => { removePhotostate() }} />
            ) : null}

            {deleteDocument.isFits ? (
                <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, delete it!" confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => { removeDocumentSelected() }}
                    onCancel={() => setDeleteDocument({
                        isFits: false,
                        documentSelected: null
                    })
                    }>You won't be able to revert this!</SweetAlert>
            ) : null}
            <div className="">
                <div className="container-fluid">
                    {documentList && documentList.length ?
                        <Row>
                            {/* <div className="head-text mb-4"> Document Detail </div> */}
                            <Col className="col-12">

                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Document</th>
                                            <th>Note</th>
                                            <th>Title</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>

                                    {documentList.map((res, i) => {
                                        return (
                                            <tbody key={i}>
                                                <tr>
                                                    <td><img src={res.document} height="50" width="50" /></td>
                                                    <td>{res.note}</td>
                                                    <td>{res.title}</td>
                                                    <td>
                                                        <div className="uil-trash-alt ml-4" onClick={() => assignDocumentSelected(res)}><i className="ti-trash"></i></div>
                                                    </td>
                                                </tr>
                                            </tbody>)
                                    })}

                                </table>

                            </Col>
                        </Row>
                        : ""}
                </div>

            </div>
        </React.Fragment>
    )
}

export default DocumentList
