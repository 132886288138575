import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom";
import { Row, Col, Card, CardBody, Modal, Button } from "reactstrap"
import API from '../../api';
import ToastMessage from 'components/Common/toastar';


function ChangeVolunteerAreaPinCode({ volunteerId, area_pin_code, onClosePinCodeData }) {
    const [modal_standard, setmodal_standard] = useState(false)
    const [formData, setFormData] = useState({
        area_pin_code: []
    });
    let [areaPinCode, setAreaPinCode] = useState([]);
    let [pinError, setPinError] = useState(false)

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        areaPinCode = area_pin_code;
        setAreaPinCode(area_pin_code);
        tog_standard()
    }, [])


    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    const updateFormData = event => {
        if (validatePinCode(event.target.name, event.target.value)) {
            setPinError(true);
        } else {
            setPinError(false)
        }
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        let data = {
            volunteer_id: volunteerId,
            access_pin_code: areaPinCode.toString()
        }
        API.post('volunteerPinUpdate', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message })
                onClosePinCodeData(false);
                setmodal_standard(false);
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })

        });
    }

    const pushToPinCodeArray = () => {
        if (validatePinCode('area_pin_code', formData.area_pin_code)) {
            setPinError(true);
            return
        } else {
            setPinError(false);
        }
        let pinCodes = [];
        if (areaPinCode && areaPinCode.length > 1) {
            pinCodes = areaPinCode.split(',')
        }
        pinCodes.push(formData.area_pin_code);
        areaPinCode = pinCodes;
        setAreaPinCode(areaPinCode.toString());
    }

    const spliceToPinCodeArray = (index) => {
        let pinCodes = [];
        if (areaPinCode && areaPinCode.length > 1) {
            pinCodes = areaPinCode.split(',')
        }
        pinCodes.splice(index, 1);
        areaPinCode = pinCodes;
        setAreaPinCode(areaPinCode.toString());
    }

    function validatePinCode(name, value) {
        if (name === 'area_pin_code') {
            if (!value) {
                return "Please Enter Pin Code";
            } else {
                if (value && (!value.match(/^[0-9]*$/i) || value.length < 6)) {
                    return "Enter a valid pin code";
                } else {
                    return null;
                }
            }
        }
    }

    return (
        <div className="App">
            <Modal isOpen={modal_standard} toggle={() => { tog_standard() }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel"> Add / Update Area Pin Code</h5>
                    <button onClick={() => { setmodal_standard(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={e => { handleSubmit(e) }}>

                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label className="control-label">Area Pin Code</label>
                                {areaPinCode && areaPinCode.length ?
                                    <div className="d-flex flex-wrap gap-2">
                                        {areaPinCode.split(',').map((res, index) => {
                                            return <span key={index} className="p-2 bg-primary badge badge-secondary">{res} <i onClick={() => spliceToPinCodeArray(index)} className="mdi mdi-close external-event"></i></span>
                                        })}
                                    </div>
                                    : ""}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <input name="area_pin_code" className="form-control" maxLength="6" value={formData.area_pin_code} label="Area Pin Code" placeholder="Area Pin Code" type="text" onChange={e => updateFormData(e)}
                                        onBlur={e => updateFormData(e)} />
                                    <span className="text-danger">{pinError ? 'Please add valid pin code' : ""}</span>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="button" color="primary" onClick={() => pushToPinCodeArray()} className="" >Add</Button>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="" >Submit</Button>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

        </div >
    );
}

export default ChangeVolunteerAreaPinCode;
