import React, { useState, useEffect } from "react"

import Switch from "react-switch"
import API from '../../api';
import ToastMessage from 'components/Common/toastar';
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
      </div>
    )
}

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
      </div>
    )
}


function ChangeDivangatStatus({ divangatId, status, onCloseData }) {

    const [switch1, setswitch1] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const [confirm_alert, setConfirmAlert] = useState(false)

    useEffect(() => {
        setswitch1(!!status)
    }, [])


    function handleChange(e) {
        let data = {
            divangat_id: divangatId,
            approval_status: !switch1
        }
        API.post('approvaDivangat', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message });
                setConfirmAlert(false)
                onCloseData(false);
                setswitch1(e)
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })

        });
    }

    function verifyConfirmation(val) {
        setConfirmAlert(val)
    }

    return (
        <div className="App">
            {confirm_alert ? (
                <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, Verify it!" confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {  handleChange(!switch1) }}
                    onCancel={() => setConfirmAlert(false)
                    }></SweetAlert>
            ) : null}
            <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                    verifyConfirmation(true)
                }}
                checked={switch1}
            />
        </div >
    );
}

export default ChangeDivangatStatus;
