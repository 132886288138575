import React from "react"
import MetaTags from 'react-meta-tags';
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"

//Import Breadcrumb
import "./datatables.scss"

const DatatableTables = ({title , columns , dataSource}) => {
  const data = {
    columns : columns,
    rows: dataSource
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid mt-5">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">{title}</CardTitle>
                  <MDBDataTable responsive striped bordered noBottomColumns={true} disableRetreatAfterSorting={true} data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables
