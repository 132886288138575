import React from "react";
import API from '../../api';
import ToastMessage from 'components/Common/toastar';
import { Link } from 'react-router-dom';
import APICommon from '../../apiAuth';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
    InputGroup,
    Form
} from "reactstrap";
import DatatableTables from "components/Common/Tables/DatatableTables";
import NotFound from "components/Common/notFound";

export class GetReport extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            isPinCode: false,
            pin_code: null,
            state: null,
            district: null,
            city: null,
            stateList: [],
            districtList: [],
            cityList: [],
            downloadUrl: [],
            reportList: null,
            rows: null,
            columns: null,
        }
    }

    componentDidMount() {
        this.getAllState();
    }

    getAllState() {
        APICommon.get('comman/all_state').then(res => {
            if (res.data.status === 1) {
                this.setState(prevState => ({ ...prevState, stateList: res.data.data, state: res.data.data[0].state_title }))
                this.getAllDistrictFromState(res.data.data[0].state_id, true);

            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error 1' })
        });
    }

    getAllDistrictFromState(state_id, isChangeFromUI) {
        let data = { state_id: state_id }
        APICommon.post('comman/state_district', data).then(res => {
            if (res.data.status === 1) {
                res.data.data.unshift({ district_title: "None" })
                this.getAllCity(state_id, res.data.data[1].district_id, true);
                this.setState(prevState => ({ ...prevState, districtList: res.data.data }))
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    getAllCity(state, district, isChangeFromUI) {
        let data = { state_id: state, district_id: district }
        APICommon.post('comman/state_district_city', data).then(res => {
            if (res.data.status === 1) {
                res.data.data.unshift({ name: "None" })
                this.setState(prevState => ({ ...prevState, cityList: res.data.data }))
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    onStateChange = e => {
        const state_id = e.target.value ? this.state.stateList.find(res => res.state_title === e.target.value).state_id : null;
        this.setState(prevState => ({ ...prevState, state: e.target.value }))
        this.getAllDistrictFromState(state_id, true)
    }

    onDistrictChange = e => {
        let district_id = e.target.value ? this.state.districtList.find(res => res.district_title === e.target.value).district_id : null;
        let state_id = this.state.state ? this.state.stateList.find(res => res.state_title === this.state.state).state_id : null;
        this.setState(prevState => ({ ...prevState, district: e.target.value }))
        if (e.target.value != 'None')
            this.getAllCity(state_id, district_id, true)
    }

    onCityChange = e => {
        this.setState(prevState => ({ ...prevState, city: e.target.value }))
    }


    handleSubmit(e) {
        e.preventDefault();
        let isValid = false;
        let data = {};
        if (this.state.isPinCode) {
            if (this.state.pin_code && this.state.pin_code.match(/^[0-9]*$/i) && this.state.pin_code.length == 6) {
                isValid = true;
                data = {
                    isPinCode: true,
                    pin_code: this.state.pin_code,
                    state: null,
                    city: null,
                    district: null
                }
            } else {
                isValid = false;
                ToastMessage({ type: 'error', message: 'Invalid Pincode' })
            }
        } else {
            isValid = true;
            if (this.state.district === 'None') {
                this.state.district = null;
            }
            if (this.state.city === 'None') {
                this.state.city = null;
            }
            data = {
                isPinCode: false,
                pin_code: "",
                state: this.state.state,
                city: this.state.city ? this.state.city : "",
                district: this.state.district ? this.state.district : ""
            }
        }


        if (isValid) {
            this.setState(prevState => ({ ...prevState, reportList: [] }));
            let columns = [
                { label: 'Member ID', field: 'member_id', sort: 'asc', width: 150 },
                { label: 'Name', field: 'member_name', sort: 'asc', width: 150 },
                { label: 'Mobile', field: 'mobile_no_1', sort: 'asc', width: 150 },
                { label: 'Pin', field: 'pin_code', sort: 'asc', width: 200 },
                { label: 'State', field: 'state', sort: 'asc', width: 200 },
                { label: 'District', field: 'district', sort: 'asc', width: 200 },
                { label: 'City', field: 'city', sort: 'asc', width: 200 },
                { label: 'Amount', field: 'payment_amount', sort: 'asc', width: 130 },
                { label: 'Payment Type', field: 'payment_type', sort: 'asc', width: 130 },

            ]
            API.post('reportMemberList', data).then(res => {
                if (res.data.status === 1) {
                    this.state.downloadUrl = res.data.download;
                    let rows = [];
                    res.data.data.map(payment => {
                        rows.push({
                            ...payment,
                            member_name: payment.name + ' ' + payment.father_name + ' ' + payment.surname_name,
                            payment_amount: payment.plan_info.plans_amount,
                            payment_type: payment.plan_info.plans_payment_type,
                        })
                    })
                    this.setState(prevState => ({ ...prevState, rows: rows, columns: columns, downloadUrl: res.data.download }))
                }
            }).catch(error => {

            });
        }
    }

    saveData(url, filename) {
        var a = document.createElement("a");
        a.href = url;
        a.download = 'users';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };

    downloadEle() {
        this.saveData(process.env.REACT_APP_REPORTURL + this.state.downloadUrl)
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Get Report</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Get Report</CardTitle>
                                        <form onSubmit={e => { this.handleSubmit(e) }}>
                                            <div>
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">Is Pin Code ?</label>
                                                        <input className="ml-4 form-check-input form-check-input" name="isPinCode" value={this.state.isPinCode} type="checkbox" onChange={e => this.setState(prevState => ({ ...prevState, isPinCode: e.target.checked }))} />
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">Pin Code</label>
                                                        <input className="form-control" disabled={!this.state.isPinCode} maxLength="6" name="pin_code" value={this.state.pin_code} label="Pin Code" placeholder="Pin Code" type="text" onChange={e => this.setState(prevState => ({ ...prevState, pin_code: e.target.value }))}
                                                            onBlur={e => this.setState(prevState => ({ ...prevState, pin_code: e.target.value }))} />
                                                    </div>
                                                </div>

                                                <Row>

                                                    {this.state.stateList.length ?
                                                        <div className="col-md-4">
                                                            <div className="mb-3 templating-select select2-container">
                                                                <label className="control-label">State</label>
                                                                <select className="form-control" disabled={this.state.isPinCode} value={this.state.state} onChange={e => this.onStateChange(e)}>
                                                                    {this.state.stateList.map((res, i) => {
                                                                        return <option key={i} value={res.state_title}>{res.state_title}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div> : ''}

                                                    <div className="col-md-4">
                                                        <div className="mb-3 templating-select select2-container">
                                                            <label className="control-label">District</label>
                                                            <select className="form-control" disabled={this.state.isPinCode} value={this.state.district} onChange={e => this.onDistrictChange(e)}>
                                                                {this.state.districtList.map((res, i) => {
                                                                    return <option key={i} value={res.district_title}>{res.district_title}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mb-3 templating-select select2-container">
                                                            <label className="control-label">Taluko</label>
                                                            <select className="form-control" disabled={this.state.isPinCode} value={this.state.city} onChange={e => this.onCityChange(e)}>
                                                                {this.state.cityList.map((res, i) => {
                                                                    return <option key={i} value={res.name}>{res.name}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </Row>

                                            </div>


                                            <div className="d-flex flex-wrap gap-2">
                                                <Button type="submit" color="primary" className="">Submit</Button>
                                                {this.state.rows != null ?
                                                    <Button type="button" color="primary" onClick={() => this.downloadEle()} className="ml-4">Download Report</Button>
                                                    : ""}
                                            </div>


                                        </form>

                                    </CardBody>
                                </Card>

                                {this.state.rows != null ?
                                    <div>
                                        <DatatableTables title={'Report Details'} columns={this.state.columns} dataSource={this.state.rows} />
                                    </div>
                                    : <NotFound />
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>

        )
    }
}


export default GetReport;