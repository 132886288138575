import { VOLUNTEER_ROLE, ADMIN_ROLE } from "common/roles";
import { decodeRole } from "common/decodedRole";

export const APIConfigBASE = (role) => {
    switch (role) {
        case VOLUNTEER_ROLE:
            return process.env.REACT_APP_APIURL + process.env.REACT_APP_APIURLVOLUNTEER + '/';
        case ADMIN_ROLE:
            return process.env.REACT_APP_APIURL +  process.env.REACT_APP_APIURLADMIN + '/';
    }
}
// export default APIConfigBASE;