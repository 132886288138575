import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import API from '../../api';
import APICommon from '../../apiAuth';

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
    InputGroup,
    Form,
    Modal
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import { useHistory, useParams } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from 'axios';

import { PaymentType, BloodGroup, Gender, MaritalStatus, Cast, RelationList, OccupationType, ContributionType } from "common/list";
import ToastMessage from "components/Common/toastar";
import Lightbox from "react-image-lightbox"
import SweetAlert from "react-bootstrap-sweetalert"
import ImageView from "components/Common/imageView";
import PaymentView from "components/Common/paymentView";
import ChangeDivangatStatus from "./changeDivangat";
import DocumentList from "./documentList";
import PaymentListDivangat from "./paymentListDivangat";

const optionGroup2 = PaymentType
let state_id;
let isChecked = false;
const AddDivangat = () => {
    const [formData, setFormData] = useState({
        plan_id: "",
        plan_info: {
            plans_id: "",
            plan_name: "",
            plans_amount: "",
            plans_payment_type: "",
            plans_validity_age: 55,
            plans_age_min: 18,
            plans_age_max: 55,
            approx_year_payment: "",
            status_plans: true
        },
        approval_status: false,
        name: "",
        father_name: "",
        grandfather_name: "",
        surname_name: "",
        blood_group: "N/A",
        current_address: "",
        village: "",
        state: "",
        death_date: "",
        city: "",
        district: "",
        pin_code: "",
        mobile_no_1: "",
        mobile_no_2: "",
        gender: "Male",
        marital_status: "Unmarried",
        cast: "Kadva",
        member_id: "",
        other_cast_name: "",
        birth_date: "",
        birth_place: "",
        native_place: "",
        age: "",
        samaj: "",
        family_info: { "member": [{ "name": "", "age": "", "relation": "Father", "mobile_no": "" }] },
        bank_info: { "bank": [{ "bank_holder_name": "", "relation": "Father", "bank_name": "", "account_no": "", "IFSC": "" }] },
        referencing_given_name: "",
        rf_mobile: "",
        rf_address: "",
        vuf_rf_name: "",
        vuf_rf_mobile: "",
        vuf_rf_address: "",
        local_vuf_volunteer_name_1: "",
        local_vuf_mobile_1: "",
        local_vuf_address_1: "",
        local_vuf_volunteer_name_2: "",
        local_vuf_mobile_2: "",
        local_vuf_address_2: "",
        user_images: ""
    });

    const [errorFormData, errorSetFormData] = useState({
        name: null,
        father_name: null,
        grandfather_name: null,
        surname_name: null,
        blood_group: null,
        current_address: null,
        village: null,
        state: null,
        city: null,
        district: null,
        death_date: null,
        pin_code: null,
        mobile_no_1: null,
        mobile_no_2: null,
        gender: null,
        marital_status: null,
        cast: null,
        other_cast_name: null,
        birth_date: null,
        birth_place: null,
        native_place: null,
        age: null,
        samaj: null,
        payment_amount: null,
        family_info: null,
        referencing_given_name: null,
        rf_mobile: null,
        rf_address: null,
        vuf_rf_name: null,
        vuf_rf_mobile: null,
        vuf_rf_address: null,
        local_vuf_volunteer_name_1: null,
        local_vuf_mobile_1: null,
        local_vuf_address_1: null,
        local_vuf_volunteer_name_2: null,
        local_vuf_mobile_2: null,
        local_vuf_address_2: null,
        user_images: null,
    });

    const [formDocumentInformation, setFormDocumentInformation] = useState({
        document: null,
        title: null,
        note: null,
        member_id: null
    });

    const [documentView, setDocumentView] = useState([]);
    const [paymentView, setPaymentView] = useState([]);
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [modalpayment_xlarge, setmodalpayment_xlarge] = useState(false)

    const [errorDocumentFormData, errorSetDocumentInformation] = useState({
        document: null,
        title: null,
        note: null,
        member_id: null
    });

    const [formPaymentInformation, setFormPaymentInformation] = useState({
        document: null,
        payment_type: "RTGS",
        payment_date: null,
        member_id: null,
        amount: null,
        payment_note: null,
    });

    const [errorPaymentFormData, errorSetPaymentInformation] = useState({
        document: null,
        payment_type: null,
        payment_date: null,
        member_id: null,
        amount: null,
        payment_note: null,
    });



    let [allPlanInfo, setPlanInfo] = useState([])
    let [allStateArray, setStateArray] = useState([])
    let [allDistrictArray, setDistrictArray] = useState([])
    let [allCityArray, setCityArray] = useState([])
    let [currentIndex, setCurrentIndex] = useState(1);
    let [planSelectedInfo, setPlanSelectedInfo] = useState();

    let [imagePreview, setImagePreview] = useState({
        currentAttachment: null,
        isFits: false
    })
    let [member_id, setMember_id] = useState();
    let [divangat_id, setDivangat_id] = useState();
    let [confirm_alert, setConfirmAlert] = useState();

    let { id } = useParams()
    const history = useHistory();
    let otherOccupation;
    let [otherOccupationError, setOtherOccupationError] = useState()
    let [showError, setShowError] = useState(false)
    let [showErrorforBank, setShowErrorForBank] = useState(false)
    let [currentIndexForArray, setCurrentIndexForArray] = useState()
    let [currentIndexForArrayBank, setCurrentIndexForArrayBank] = useState()
    let [paymentShowView, setPaymentShowView] = useState([]);
    let [imgData, setImgData] = useState(null);
    let [imgDataPayment, setPaymentImgData] = useState(null);

    
    function handleAddRowNested() {
        formData.family_info.member.push({
            "name": "", "age": "", "relation": "Father", "mobile_no": ""
        })
        const item = formData.family_info.member
        setFormData({
            ...formData,
            family_info: {
                member: item
            }
        });
    }

    function handleAddRowNestedBank() {
        formData.bank_info.bank.push({ "bank_holder_name": "", "relation": "Father", "bank_name": "", "account_no": "", "IFSC": "" })
        const item = formData.bank_info.bank
        setFormData({
            ...formData,
            bank_info: {
                bank: item
            }
        });
    }

    function validate(name, value) {
        if (name === 'name' || name === 'father_name' || name === 'grandfather_name' || name === 'surname_name' || name === 'village' || name === 'pin_code' || name === 'district' || name === 'current_address' ||
            name === 'birth_place' || name === 'native_place' || name === 'death_date' || name === 'samaj' ||
            name === 'referencing_given_name' || name === 'rf_mobile' || name === 'rf_address' || name === 'vuf_rf_name' || name === 'vuf_rf_mobile' ||
            name === 'vuf_rf_address' || name === 'local_vuf_volunteer_name_1' || name === 'local_vuf_mobile_1' || name === 'local_vuf_address_1') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }

        switch (name) {
            case "email":
                if (!value) {
                    return "Please enter email";
                } else if (
                    !value.match(/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i)
                ) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            case "mobile_no_1":
                if (!value) {
                    return "Please enter mobile number 1";
                } else if (!value.match(/^[0-9]*$/i) || value.length > 10) {
                    return "Enter a valid mobile number 1";
                } else {
                    return "";
                }
            case "mobile_no_2":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid mobile number 2";
                } else {
                    return "";
                }
            case "vuf_rf_mobile":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid mobile number";
                } else {
                    return "";
                }
            case "rf_mobile":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid mobile number";
                } else {
                    return "";
                }
            case "local_vuf_mobile_1":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid mobile number";
                } else {
                    return "";
                }

            case "pin_code":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 6)) {
                    return "Enter a valid pin code";
                } else {
                    return "";
                }
            case "rf_pin_code":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 6)) {
                    return "Enter a valid pin code";
                } else {
                    return "";
                }


            default: {
                return "";
            }
        }
    }

    function validatePaymentForm(name, value) {
        if (name === 'payment_receipt' || name === 'amount' || name === 'payment_date' || name === 'payment_note') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }


        switch (name) {
            case "amount":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid amount";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    function validateNachForm(name, value) {
        if (name === 'bank_name' || name === 'bank_cheque_no' || name === 'bank_holder_name' || name === 'ifsc' || name === 'micr' || name === 'account_number') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }
        switch (name) {
            case "account_number":
                if (value && (!value.match(/^[0-9]*$/i))) {
                    return "Enter a valid bank account number";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    function validateImageUpload(image) {
        var filePath = image.target.value;
        // Allowing file type
        var allowedExtensions =
            /(\.jpg|\.jpeg|\.png)$/i;

        if (!allowedExtensions.exec(filePath)) {
            ToastMessage({ type: 'error', message: 'Invalid file type' })
            image.value = '';
            return false;
        } else {
            return true;
        }
    }

    function uploadDocument(event) {
        setImgData(null);
        imgData = null;
        if (validateImageUpload(event)) {
            if (event.target.files[0]) {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData(reader.result);
                    imgData = reader.result;
                });
                reader.readAsDataURL(event.target.files[0]);
            }
            formDocumentInformation.document = event.target.files[0];
            setFormDocumentInformation({
                ...formPaymentInformation,
                document: event.target.files[0]
            })
            errorSetDocumentInformation({
                ...errorDocumentFormData,
                [event.target.name]: ''
            });
            ToastMessage({ type: 'success', message: 'Image added' })
        }
    }

    function convertErrorMessage(e) {
        return e.replace(/_/g, " ");
    }

    function removeNestedRows(index) {
        currentIndexForArray = index;
        setCurrentIndexForArray(index)
        setShowError(true);
    }

    function removeNestedRowsBank(index) {
        currentIndexForArrayBank = index;
        setCurrentIndexForArrayBank(index)
        setShowErrorForBank(true);
    }

    function deleteRow() {
        formData.family_info.member.splice(currentIndexForArray, 1)
        const item = formData.family_info.member
        setFormData({
            ...formData,
            family_info: {
                member: item
            }
        });
        setShowError(false);
        currentIndexForArray = undefined;
        setCurrentIndexForArray(undefined)

    }

    function deleteRowBank() {
        formData.bank_info.bank.splice(currentIndexForArrayBank, 1)
        const item = formData.bank_info.bank
        setFormData({
            ...formData,
            bank_info: {
                bank: item
            }
        });
        setShowErrorForBank(false);
        currentIndexForArrayBank = undefined;
        setCurrentIndexForArrayBank(undefined)
    }



    useEffect(() => {
        setDivangat_id(id)
        divangat_id = id;
        if (id) {
            getSingleDivangatDetail();
        } else {
            getAllState()
        }
        getAllPlans();

    }, [])


    function getPlanFromSelectedID(e) {
        let id = e.target ? e.target.value : e;
        let plan = allPlanInfo.find(res => res.plans_id.toString() === id.toString());
        setPlanSelectedInfo(plan)
    }

    function getSingleDivangatDetail() {
        let data = { divangat_id: divangat_id }
        API.post('singleDivangatInfo', data).then(res => {
            if (res.data.status === 1) {
                let data = res.data.data.divangat;
                setMember_id(data.member_id);
                let bank_nach = res.data.data.bank_info ? res.data.data.bank_info.bank[0] : undefined;
                let payment = res.data.data.payment ? res.data.data.payment : undefined
                delete data.status_verified;
                delete data.plan_info.status_plans;
                if (res.data.data.document && res.data.data.document.length) {
                    setDocumentView(res.data.data.document);
                }
                if (res.data.data.payment && res.data.data.payment.length) {
                    setPaymentView(res.data.data.payment);
                }
                setFormData(data);
                formData.state = data.state;
                formData.district = data.district;
                formData.plan_id = data.plan_id;
                getAllState();
                getPlanFromSelectedID(data.plan_id)
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    function getSingleMemberDetail() {
        let data = { member_id: member_id }
        API.post('single_member', data).then(res => {
            if (res.data.status === 1) {
                let data = res.data.data.member;

                setFormData({
                    plan_id: data.plan_id,
                    plan_info: data.plan_info,
                    name: data.name,
                    father_name: data.father_name,
                    grandfather_name: data.grandfather_name,
                    surname_name: data.surname_name,
                    blood_group: data.blood_group,
                    current_address: data.current_address,
                    village: data.village,
                    state: data.state,
                    death_date: null,
                    city: data.city,
                    district: data.district,
                    pin_code: data.pin_code,
                    mobile_no_1: data.mobile_no_1,
                    mobile_no_2: data.mobile_no_2,
                    gender: data.gender,
                    marital_status: data.marital_status,
                    cast: data.cast,
                    member_id: data.member_id,
                    other_cast_name: data.other_cast_name,
                    birth_date: data.birth_date,
                    birth_place: data.birth_place,
                    native_place: data.native_place,
                    age: data.age,
                    samaj: data.samaj,
                    family_info: data.family_info,
                    bank_info: { "bank": [{ "bank_holder_name": "", "relation": "Father", "bank_name": "", "account_no": "", "IFSC": "" }] },
                    referencing_given_name: data.referencing_given_name,
                    rf_mobile: data.rf_mobile_no,
                    rf_address: data.rf_address,
                    vuf_rf_name: null,
                    vuf_rf_mobile: null,
                    vuf_rf_address: null,
                    local_vuf_volunteer_name_1: null,
                    local_vuf_mobile_1: null,
                    local_vuf_address_1: null,
                    local_vuf_volunteer_name_2: null,
                    local_vuf_mobile_2: null,
                    local_vuf_address_2: null,
                    user_images: data.user_images
                    // bank_info: { "bank": [{ "bank_holder_name": "", "relation": "Father", "bank_name": "", "account_no": "", "IFSC": "" }] },
                });
                formData.state = data.state;
                formData.district = data.district;
                formData.plan_id = data.plan_id;
                getAllState();
                getPlanFromSelectedID(data.plan_id)
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    function convertDate(date) {
        if (date) {
            var parts = date.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    }

    function getAllPlans() {
        API.get('all_plans').then(res => {
            if (res.data.status === 1) {
                setPlanInfo(res.data.data);
                allPlanInfo = res.data.data;
                if (!member_id) {
                    formData.plan_id = res.data.data[0].plans_id
                    getPlanFromSelectedID(res.data.data[0].plans_id)
                } else {
                    getPlanFromSelectedID(formData.plan_id)
                }
            }
        }).catch(error => {

        });
    }

    function getAllState() {
        APICommon.get('comman/all_state').then(res => {
            if (res.data.status === 1) {
                setStateArray(res.data.data);
                if (!id) {
                    getAllDistrictFromState(res.data.data[0].state_id, true);
                    formData.state = res.data.data[0].state_title;
                    state_id = res.data.data[0].state_id;
                } else {
                    state_id = formData.state ? res.data.data.find(res => res.state_title === formData.state).state_id : null;
                    getAllDistrictFromState(state_id, false)
                }
            }
        }).catch(error => {

        });
    }

    function getAllDistrictFromState(state_id, isChangeFromUI) {
        let data = { state_id: state_id }
        APICommon.post('comman/state_district', data).then(res => {
            if (res.data.status === 1) {
                setDistrictArray(res.data.data);
                if (!id || isChangeFromUI) {
                    getAllCity(state_id, res.data.data[0].district_id, true);
                    formData.district = res.data.data[0].district_title;
                } else {
                    let district_id = formData.district ? res.data.data.find(res => res.district_title === formData.district).district_id : null;
                    getAllCity(state_id, district_id, false)
                }
            }
        }).catch(error => {

        });
    }

    function getAllCity(state, district, isChangeFromUI) {
        let data = { state_id: state, district_id: district }
        APICommon.post('comman/state_district_city', data).then(res => {
            if (res.data.status === 1) {
                setCityArray(res.data.data);
                if (!id || isChangeFromUI) {
                    formData.city = res.data.data[0].name;
                }
                // if (member_id && !isChecked) {
                //     isChecked = true

                // }
            }
        }).catch(error => {

        });
    }

    const onStateChange = e => {
        state_id = e.target.value ? allStateArray.find(res => res.state_title === e.target.value).state_id : null;
        formData.state = e.target.value;
        getAllDistrictFromState(state_id, true)
    }

    const onDistrictChange = e => {
        let district_id = e.target.value ? allDistrictArray.find(res => res.district_title === e.target.value).district_id : null;
        formData.district = e.target.value;
        getAllCity(state_id, district_id, true)
    }

    const onCityChange = e => {
        formData.city = e.target.value;
        setFormData({
            ...formData,
            city: e.target.value
        })
    }

    const updateFormData = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
        errorSetFormData({
            ...errorFormData,
            [event.target.name]: validate(event.target.name, event.target.value)
        });
    }

    const updateDocumentData = event => {
        setFormDocumentInformation({
            ...formDocumentInformation,
            [event.target.name]: event.target.value
        });
        errorSetDocumentInformation({
            ...errorDocumentFormData,
            [event.target.name]: validateDocumentForm(event.target.name, event.target.value)
        });
    }

    const updatePaymentData = event => {
        setFormPaymentInformation({
            ...formPaymentInformation,
            [event.target.name]: event.target.value
        });
        errorSetPaymentInformation({
            ...errorPaymentFormData,
            [event.target.name]: validatePaymentForm(event.target.name, event.target.value)
        });
    }

    function validateDocumentForm(name, value) {
        if (name === 'title' || name === 'note' || name === 'document') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }
    }

    function validatePaymentForm() {
        if (name === 'document' || name === 'payment_type' || name === 'payment_date' || name === 'amount' || name === 'payment_note') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }
    }

    const updatePaymentFormData = event => {
        setFormPaymentInformation({
            ...formPaymentInformation,
            [event.target.name]: event.target.value
        });
        errorSetFormPaymentInformation({
            ...errorFormPaymentFormData,
            [event.target.name]: validatePaymentForm(event.target.name, event.target.value)
        });
    }

    const updateNachFormData = event => {
        setFormNachInformation({
            ...formNachInformation,
            [event.target.name]: event.target.value
        });
        errorSetFormNachInformation({
            ...errorFormNachInformation,
            [event.target.name]: validateNachForm(event.target.name, event.target.value)
        });
    }

    const updateFamilyInformationFormData = event => {
        let index = event.target.name.split('-')[0];
        let name = event.target.name.split('-')[1];
        formData.family_info.member[index][name] = event.target.value;
        const item = formData.family_info.member;
        setFormData({
            ...formData,
            family_info: {
                member: item
            }
        });
    }

    const updateInformationFormDataBank = event => {
        let index = event.target.name.split('-')[0];
        let name = event.target.name.split('-')[1];
        formData.bank_info.bank[index][name] = event.target.value;
        const item = formData.bank_info.bank;
        setFormData({
            ...formData,
            bank_info: {
                bank: item
            }
        });
    }

    const assigDateAndAge = e => {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currerntYear = new Date().getFullYear();
        formData.birth_date = day + '-' + month + '-' + year;
        formData.age = currerntYear - year;
    }

    const assigDeathDate = e => {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currerntYear = new Date().getFullYear();
        formData.death_date = day + '-' + month + '-' + year;
    }

    const updatePaymentDate = e => {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        setFormData({
            ...formData,
            payment_date: day + '-' + month + '-' + year,
        });
    }

    const updatePaymentDateSubmitForm = e => {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        setFormPaymentInformation({
            ...formPaymentInformation,
            payment_date: day + '-' + month + '-' + year,
        });
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_xlarge() {
        setmodal_xlarge(!modal_xlarge)
        removeBodyCss()
    }



    const { plan_name, plans_amount, plans_validity_age } = formData;

    function uploadMemberAttachment(upload, type, key) {
        upload.preventDefault()
        if (validateImageUpload(upload)) {
            var bodyFormData = new FormData();
            bodyFormData.append([key], upload.target.files[0]);
            API.post(type + '/' + member_id, bodyFormData).then(res => {
                if (res.data.status === 1) {
                    if (key === 'user') {
                        formDataUploads.user_images = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_images: res.data.data })
                    } else if (key === 'aadhar') {
                        formDataUploads.user_aadhar_cad = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_aadhar_cad: res.data.data })
                    } else if (key === 'pan') {
                        formDataUploads.user_pan_card = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_pan_card: res.data.data })
                    } else if (key === 'cheque') {
                        formDataUploads.user_cheque = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_chequesignechar: res.data.data })
                    } else if (key === 'signechar') {
                        formDataUploads.user_signechar = res.data.data;
                        setFormDataUploads({ ...formDataUploads, user_signechar: res.data.data })
                    }
                    ToastMessage({ type: 'success', message: res.data.message })
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch((error) => {
                ToastMessage({ type: 'error', message: 'Internal Server Error' })

            })
        }
    }

    function validateImageUpload(image) {
        var filePath = image.target.value;
        // Allowing file type
        var allowedExtensions =
            /(\.jpg|\.jpeg|\.png)$/i;

        if (!allowedExtensions.exec(filePath)) {
            ToastMessage({ type: 'error', message: 'Invalid file type' })
            image.value = '';
            return false;
        } else {
            return true;
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        let arraysFamilyInfo = formData.family_info.member;
        let isError = false
        arraysFamilyInfo.map((res) => {
            if (!res.age || !res.name || !res.mobile_no || !res.relation) {
                ToastMessage({ type: 'error', message: 'Invalid Family Information' })
                isError = true;
            }
        })

        let arraysBankInfo = formData.bank_info.bank;
        arraysBankInfo.map((res) => {
            if (!res.bank_holder_name || !res.bank_name || !res.relation || !res.account_no || !res.IFSC) {
                ToastMessage({ type: 'error', message: 'Invalid Bank Information' })
                isError = true;
            }
        })

        if (isError) {
            return;
        }


        let validationErrors = {};
        Object.keys(formData).forEach(name => {
            const error = validate(name, formData[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            errorSetFormData(validationErrors);
            return;
        }

        let plan_info = allPlanInfo.find(res => res.plans_id === formData.plan_id)
        formData.plan_info = plan_info;
        delete formData.approval_status;

        if (divangat_id) {
            let data = {
                divangat_id: divangat_id,
                member_id: member_id,
                ...formData
            }
            API.post('editDivangat', data).then(res => {
                if (res.data.status === 1) {
                    isChecked = false;
                    isError = false;
                    ToastMessage({ type: 'success', message: res.data.message })
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Invalid Family Information' })
            });
        } else {


            let data = {
                member_id: member_id,
                payment_amount: planSelectedInfo.plans_amount,
                ...formData
            }

            API.post('addDivangat', data).then(res => {
                if (res.data.status === 1) {
                    setMember_id(member_id)
                    isError = false;
                    ToastMessage({ type: 'success', message: res.data.message })
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Invalid Family Information' })
            });
        }
    }


    function handleDocumentSubmit(e) {
        e.preventDefault()
        let validationErrors = {};
        Object.keys(formDocumentInformation).forEach(name => {
            const error = validateDocumentForm(name, formDocumentInformation[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            errorSetDocumentInformation(validationErrors);
            return;
        }
        var bodyFormData = new FormData();
        bodyFormData.append('document', formDocumentInformation.document);
        bodyFormData.append('title', formDocumentInformation.title);
        bodyFormData.append('note', formDocumentInformation.note);
        bodyFormData.append('member_id', formData.member_id);

        API.post('divangat_document_update/' + divangat_id, bodyFormData).then(res => {
            if (res.data.status === 1) {
                setDocumentView([]);
                let data = { divangat_id: divangat_id }
                API.post('singleDivangatInfo', data).then(res => {
                    if (res.data.status === 1) {
                        let data = res.data.data.divangat;
                        if (res.data.data.document && res.data.data.document.length) {
                            setDocumentView(res.data.data.document);
                            setmodal_xlarge(false);
                        } else {
                            setDocumentView([]);
                        }
                    } else {
                        ToastMessage({ type: 'error', message: res.data.message })
                    }
                }).catch(error => {
                    ToastMessage({ type: 'error', message: 'Internal Server Error' })
                });
                ToastMessage({ type: 'success', message: res.data.message })
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Invalid Family Information' })
        });
    }

    function handlePaymentSubmit(e) {
        e.preventDefault()
        let validationErrors = {};
        Object.keys(formPaymentInformation).forEach(name => {
            const error = validatePaymentForm(name, formPaymentInformation[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            errorSetPaymentInformation(validationErrors);
            return;
        }
        var bodyFormData = new FormData();
        bodyFormData.append('document', formPaymentInformation.document);
        bodyFormData.append('payment_type', formPaymentInformation.payment_type);
        bodyFormData.append('payment_date', formPaymentInformation.payment_date);
        bodyFormData.append('amount', formPaymentInformation.amount);
        bodyFormData.append('payment_note', formPaymentInformation.payment_note);
        bodyFormData.append('member_id', formData.member_id);

        API.post('divangat_payment_update/' + divangat_id, bodyFormData).then(res => {
            if (res.data.status === 1) {
                setPaymentView([]);
                let data = { divangat_id: divangat_id }
                API.post('singleDivangatInfo', data).then(res => {
                    if (res.data.status === 1) {
                        if (res.data.data.payment && res.data.data.payment.length) {
                            setPaymentView(res.data.data.payment);
                            setmodalpayment_xlarge(false)
                        } else {
                            setPaymentView([]);
                        }
                    } else {
                        ToastMessage({ type: 'error', message: res.data.message })
                    }
                }).catch(error => {
                    ToastMessage({ type: 'error', message: 'Internal Server Error' })
                });
                ToastMessage({ type: 'success', message: res.data.message })
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Invalid Family Information' })
        });

    }

    function handleNachSubmit(e) {
        if (planSelectedInfo.plans_payment_type.toLowerCase() === 'one time') {
            setCurrentIndex(3);
            return
        } else {
            e.preventDefault()
            let validationErrors = {};
            Object.keys(formNachInformation).forEach(name => {
                const error = validateNachForm(name, formNachInformation[name]);
                if (error && error.length > 0) {
                    validationErrors[name] = error;
                }
            });
            if (Object.keys(validationErrors).length > 0) {
                errorSetFormNachInformation(validationErrors);
                return;
            }

            let data = {
                member_id: member_id,
                ...formNachInformation
            }

            API.post('member_update_nach', data).then(res => {
                if (res.data.status === 1) {
                    setCurrentIndex(3);
                    ToastMessage({ type: 'success', message: res.data.message })
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Invalid Family Information' })
            });
        }
    }

    function assignSelectedIndex(index) {
        if (index === 1) {
            setCurrentIndex(index)
        }
        if (index > 1 && member_id) {
            setCurrentIndex(index)
        }
    }

    function uploadPaymentReceipt(event) {

        setPaymentImgData(null);
        imgDataPayment = null;
        if (validateImageUpload(event)) {
            if (event.target.files[0]) {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setPaymentImgData(reader.result);
                    imgDataPayment = reader.result;
                });
                reader.readAsDataURL(event.target.files[0]);
            }
            formPaymentInformation.document = event.target.files[0];
            setFormPaymentInformation({
                ...formPaymentInformation,
                document: event.target.files[0]
            })
            // errorSetPaymentInformation({
            //     ...formPaymentInformation,
            //     [event.target.name]: ''
            // });
            ToastMessage({ type: 'success', message: 'Image added' })
        }
    }


    function findMemeberFromID() {
        const memberid = document.getElementById('member_id');
        setMember_id(memberid.value)
        member_id = memberid.value;
        getSingleMemberDetail();
    }

    function verifyConfirmation() {
        setConfirmAlert(true)
    }

    function verifyMember() {
        let data = {
            divangat_id: divangat_id,
            approval_status: true
        }
        API.post('approvaDivangat', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message });
                setFormData({ ...formData, approval_status: true })
                setConfirmAlert(false)
            } else {
                setConfirmAlert(false)
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            setConfirmAlert(false)
            ToastMessage({ type: 'error', message: 'Internal Server Error' })

        });
    }

    function setApprovalStatus(status) {
        formData.approval_status = !status
        setFormData({
            ...formData,
            approval_status: status
        })
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{divangat_id ? 'Edit Divangat' : 'Add Divangat'}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Divangat" breadcrumbItem={divangat_id ? 'Edit Divangat' : 'Add Divangat'} />
                    {confirm_alert ? (
                        <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, Verify it!" confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => { verifyMember() }}
                            onCancel={() => setConfirmAlert(false)
                            }>You won't be able to revert this!</SweetAlert>
                    ) : null}

                    {imagePreview.isFits ? (
                        <Lightbox
                            mainSrc={imagePreview.currentAttachment}
                            enableZoom={false}
                            onCloseRequest={() => { removePhotostate() }} />
                    ) : null}
                    {showError ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmButtonText="Yes, delete it!"
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                deleteRow()
                            }}
                            onCancel={() => setShowError('')
                            }
                        >
                            You won't be able to revert this!
                            </SweetAlert>
                    ) : null}
                    {showErrorforBank ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmButtonText="Yes, delete it!"
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                deleteRowBank()
                            }}
                            onCancel={() => setShowErrorForBank('')
                            }
                        >
                            You won't be able to revert this!
                            </SweetAlert>
                    ) : null}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>

                                    <div className="view-plan-container">
                                        <CardTitle>{divangat_id ? 'Edit Divangat' : 'Add Divangat'} Information </CardTitle>

                                        {divangat_id && formData.plan_id ?
                                            <div className="view-plan-container">
                                                <div className="margin-member">
                                                    {formData.approval_status}
                                                    <ChangeDivangatStatus divangatId={divangat_id} status={formData.approval_status} onCloseData={(e) => setApprovalStatus(!formData.approval_status)} />
                                                </div>
                                            </div>
                                            : ""}
                                    </div>


                                    {!divangat_id ?
                                        <div className="col-md-8 mb-3 ">
                                            <div className="mb-3">
                                                <label className="control-label">Find Member</label>
                                                <div className="d-flex w-100">
                                                    <div className="col-md-4 mb-3 mr-4 margin-btn">
                                                        <input name="name" className="form-control" disabled={divangat_id} id="member_id" maxLength="50" label="Member ID" placeholder="Member ID" type="text" />
                                                    </div>

                                                    <div>
                                                        <Button type="submit" disabled={divangat_id} color="primary" className="mb-3" onClick={() => findMemeberFromID()}>Find Member</Button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        : ""}


                                    <form onSubmit={e => { handleSubmit(e) }}>

                                        {formData.plan_info ?
                                            <div className="row">
                                                {/* <div className="col-md-3">
                                                    <div className="mb-3 templating-select select2-container">
                                                        <label className="control-label">Select Contribution Option</label>
                                                        <select className="form-control" name="plan_id" value={formData.plan_id} onChange={e => { getPlanFromSelectedID(e); updateFormData(e) }}>
                                                            {allPlanInfo.map((res, i) => {
                                                                return <option key={i} value={res.plans_id}>{res.plan_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                {formData.plan_info.plans_id ?
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="mb-3 templating-select select2-container">
                                                                <label className="control-label">Plan Name</label>
                                                                <div>{formData.plan_info.plan_name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="mb-3 templating-select select2-container">
                                                                <label className="control-label">Amount</label>
                                                                <div>{formData.plan_info.plans_amount}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="mb-3 templating-select select2-container">
                                                                <label className="control-label">Contribution Type</label>
                                                                <div>{formData.plan_info.plans_payment_type}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="mb-3 templating-select select2-container">
                                                                <label className="control-label">Approx Year</label>
                                                                <div>{formData.plan_info.approx_year_payment}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="mb-3 templating-select select2-container">
                                                                <label className="control-label">Valid Age</label>
                                                                <div>{formData.plan_info.plans_validity_age}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <div className="mb-3 templating-select select2-container">
                                                                <label className="control-label">Max Age</label>
                                                                <div>{formData.plan_info.plans_age_max}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <div className="mb-3 templating-select select2-container">
                                                                <label className="control-label">Min Age</label>
                                                                <div>{formData.plan_info.plans_age_min}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>
                                            : ""}

                                        <div className="head-text mb-4"> Personal Information </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Name</label>
                                                    <input name="name" className="form-control" maxLength="50" value={formData.name} label="Member Name" placeholder="Member Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.name}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Father / Husband Name</label>
                                                    <input className="form-control" maxLength="50" name="father_name" value={formData.father_name} label="Father Name" placeholder="Father Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.father_name}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Grand Father/ Father-in-Law Name</label>
                                                    <input className="form-control" maxLength="50" name="grandfather_name" value={formData.grandfather_name} label="Grand Father Name" placeholder="Grand Father Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.grandfather_name}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label className="control-label">Surname Name</label>
                                                    <input className="form-control" maxLength="50" name="surname_name" value={formData.surname_name} label="Surname Name" placeholder="Surname Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.surname_name}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-1">
                                                <div className="mb-3">
                                                    <label className="control-label">Blood Group</label>
                                                    <select className="form-control" name="blood_group" value={formData.blood_group} onChange={e => updateFormData(e)}>
                                                        {BloodGroup.map((res, i) => {
                                                            return <option key={i} value={res.value}>{res.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="row">
                                            <div className="col-md-1">
                                                <div className="mb-3">
                                                    <label className="control-label">Gender</label>
                                                    <select className="form-control" name="gender" value={formData.gender} onChange={e => updateFormData(e)}>
                                                        {Gender.map((res, i) => {
                                                            return <option key={i} value={res.value}>{res.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="mb-3">
                                                    <Label>Birth Date</Label>
                                                    <InputGroup>
                                                        <Flatpickr value={convertDate(formData.birth_date)} className="form-control d-block" placeholder="dd M,yyyy" name="birth_date"
                                                            onChange={e => assigDateAndAge(e)}
                                                            onBlur={e => assigDateAndAge(e)}
                                                            options={{ altInput: true }} />
                                                    </InputGroup>
                                                </div>
                                            </div>


                                            <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label className="control-label">Mobile number 1</label>
                                                    <input className="form-control" name="mobile_no_1" maxLength="10" value={formData.mobile_no_1} label="Mobile number 1" placeholder="Mobile number 1" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.mobile_no_1}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label className="control-label">Mobile number 2</label>
                                                    <input className="form-control" name="mobile_no_2" maxLength="10" value={formData.mobile_no_2} label="Mobile number 2" placeholder="Mobile number 2" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.mobile_no_2}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label className="control-label">Marital Status</label>
                                                    <select className="form-control" name="marital_status" value={formData.marital_status} onChange={e => updateFormData(e)}>
                                                        {MaritalStatus.map((res, i) => {
                                                            return <option key={i} value={res.value}>{res.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Birth Place</label>
                                                    <input className="form-control" maxLength="50" name="birth_place" value={formData.birth_place} label="Birth Place" placeholder="Birth Place" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.birth_place}</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">



                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <Label>Death Date</Label>
                                                    <InputGroup>
                                                        <Flatpickr value={convertDate(formData.death_date)} className="form-control d-block" placeholder="dd M,yyyy" name="birth_date"
                                                            onChange={e => assigDeathDate(e)}
                                                            onBlur={e => assigDeathDate(e)}
                                                            options={{ altInput: true }} />
                                                    </InputGroup>
                                                    <span className="text-danger">{errorFormData.death_date}</span>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Native Place</label>

                                                    <input className="form-control" maxLength="50" name="native_place" value={formData.native_place} label="Native Place" placeholder="Native Place" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.native_place}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Samaj</label>
                                                    <input className="form-control" name="samaj" maxLength="50" value={formData.samaj} label="Samaj" placeholder="Samaj" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.samaj}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <div className="mb-3">
                                                    <label className="control-label">Cast</label>
                                                    <select className="form-control" name="cast" value={formData.cast} onChange={e => updateFormData(e)}>
                                                        {Cast.map((res, i) => {
                                                            return <option key={i} value={res.value}>{res.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>


                                            {formData.cast === 'Other' ?
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                        <label className="control-label">Other Cast Name</label>
                                                        <input className="form-control" name="other_cast_name" maxLength="50" value={formData.other_cast_name} label="Other Cast Name" placeholder="Other Cast Name" type="text" onChange={e => updateFormData(e)}
                                                            onBlur={e => updateFormData(e)} />
                                                        <span className="text-danger">{errorFormData.other_cast_name}</span>
                                                    </div>
                                                </div> : ''}

                                        </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Current Address</label>
                                                    <textarea className="form-control" maxLength="100" name="current_address" value={formData.current_address} label="Current Address" placeholder="Current Address" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)}></textarea>
                                                    <span className="text-danger">{errorFormData.current_address}</span>
                                                </div>
                                            </div>


                                            {allStateArray.length ?
                                                <div className="col-md-3">
                                                    <div className="mb-3 templating-select select2-container">
                                                        <label className="control-label">State</label>
                                                        <select className="form-control" value={formData.state} onChange={e => onStateChange(e)}>
                                                            {allStateArray.map((res, i) => {
                                                                return <option key={i} value={res.state_title}>{res.state_title}</option>
                                                            })}
                                                        </select>
                                                        {/* <Select name="state" value={formData.state} isMulti={false} onChange={() => onStateChange()} options={allStateArray}
                                                                classNamePrefix="select2-selection" closeMenuOnSelect={true} /> */}
                                                    </div>
                                                </div> : ''}

                                            {allDistrictArray.length ?
                                                <div className="col-md-3">
                                                    <div className="mb-3 templating-select select2-container">
                                                        <label className="control-label">District</label>
                                                        <select className="form-control" value={formData.district} onChange={e => onDistrictChange(e)}>
                                                            {allDistrictArray.map((res, i) => {
                                                                return <option key={i} value={res.district_title}>{res.district_title}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                : ''}

                                            {allCityArray.length ?
                                                <div className="col-md-3">
                                                    <div className="mb-3 templating-select select2-container">
                                                        <label className="control-label">City</label>
                                                        <select className="form-control" value={formData.city} onChange={e => onCityChange(e)}>
                                                            {allCityArray.map((res, i) => {
                                                                return <option key={i} value={res.name}>{res.name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Village</label>

                                                    <input className="form-control" maxLength="50" name="village" value={formData.village} label="Village" placeholder="Village" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.village}</span>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Pin Code</label>

                                                    <input className="form-control" maxLength="6" name="pin_code" value={formData.pin_code} label="Pin Code" placeholder="Pin Code" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.pin_code}</span>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="head-text mb-4"> Family Information </div>

                                        {formData.family_info && formData.family_info.member.length ?
                                            formData.family_info.member.map((res, index) => {
                                                return (<div className="row" key={index}>
                                                    <div className="col-md-3 mb-3">
                                                        <input className="form-control" name={index + "-name"} value={res.name} label="Name" placeholder="Name" type="text" onChange={e => updateFamilyInformationFormData(e)}
                                                            onBlur={e => updateFamilyInformationFormData(e)} />
                                                    </div>

                                                    <div className="col-md-2 mb-3">
                                                        <input className="form-control" maxLength="2" name={index + "-age"} label="Age" value={res.age} placeholder="Age" type="text" onChange={e => updateFamilyInformationFormData(e)}
                                                            onBlur={e => updateFamilyInformationFormData(e)} />
                                                    </div>

                                                    <div className="col-md-3 mb-3">
                                                        <select className="form-control" name={index + "-relation"} value={res.relation} onChange={e => updateFamilyInformationFormData(e)}>
                                                            {RelationList.map((res, i) => {
                                                                return <option key={i} value={res.value}>{res.label}</option>
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2 mb-3">
                                                        <input className="form-control" maxLength="10" name={index + "-mobile_no"} value={res.mobile_no} label="Mobile Number" placeholder="Mobile Number" type="text" onChange={e => updateFamilyInformationFormData(e)}
                                                            onBlur={e => updateFamilyInformationFormData(e)} />
                                                    </div>

                                                    <Col lg={2} className="align-self-center mb-3">
                                                        <div className="d-grid">
                                                            <input data-repeater-delete type="button" onClick={() => { removeNestedRows(index) }} className="btn btn-primary" value="Delete" />
                                                        </div>
                                                    </Col>
                                                </div>)
                                            })
                                            : ""}
                                        <Button onClick={() => { handleAddRowNested() }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Add</Button>


                                        <div className="head-text mb-4"> Bank Information </div>

                                        {formData.bank_info && formData.bank_info.bank.length ?
                                            formData.bank_info.bank.map((res, index) => {
                                                return (<div className="row" key={index}>
                                                    <div className="col-md-3 mb-3">
                                                        <input className="form-control" name={index + "-bank_holder_name"} value={res.bank_holder_name} label="Bank holder name" placeholder="Bank holder name" type="text" onChange={e => updateInformationFormDataBank(e)}
                                                            onBlur={e => updateInformationFormDataBank(e)} />
                                                    </div>

                                                    <div className="col-md-2 mb-3">
                                                        <select className="form-control" name={index + "-relation"} value={res.relation} onChange={e => updateInformationFormDataBank(e)}>
                                                            {RelationList.map((res, i) => {
                                                                return <option key={i} value={res.value}>{res.label}</option>
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2 mb-3">
                                                        <input className="form-control" name={index + "-bank_name"} label="bank_name" value={res.bank_name} placeholder="Bank name" type="text" onChange={e => updateInformationFormDataBank(e)}
                                                            onBlur={e => updateInformationFormDataBank(e)} />
                                                    </div>


                                                    <div className="col-md-2 mb-3">
                                                        <input className="form-control" maxLength="20" name={index + "-account_no"} value={res.account_no} label="Account Number" placeholder="Account Number" type="text" onChange={e => updateInformationFormDataBank(e)}
                                                            onBlur={e => updateInformationFormDataBank(e)} />
                                                    </div>

                                                    <div className="col-md-2 mb-3">
                                                        <input className="form-control" maxLength="20" name={index + "-IFSC"} value={res.IFSC} label="IFSC Number" placeholder="IFSC Number" type="text" onChange={e => updateInformationFormDataBank(e)}
                                                            onBlur={e => updateInformationFormDataBank(e)} />
                                                    </div>

                                                    <Col lg={1} className="align-self-center mb-3">
                                                        <div className="d-grid">
                                                            <input data-repeater-delete type="button" onClick={() => { removeNestedRowsBank(index) }} className="btn btn-primary" value="Delete" />
                                                        </div>
                                                    </Col>
                                                </div>)
                                            })
                                            : ""}
                                        <Button onClick={() => { handleAddRowNestedBank() }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Add Bank Detail</Button>



                                        <div className="head-text mb-4"> Reference Information </div>



                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Referencing Given Name</label>

                                                    <input className="form-control" maxLength="50" value={formData.referencing_given_name} name="referencing_given_name" label="Referencing Given Name" placeholder="Referencing Given Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.referencing_given_name}</span>
                                                </div>
                                            </div>


                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Referencing Mobile Number</label>

                                                    <input className="form-control" maxLength="10" value={formData.rf_mobile} name="rf_mobile" label="Referencing Mobile Number" placeholder="Referencing Mobile Number" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.rf_mobile}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Referencing Address</label>

                                                    <textarea className="form-control" maxLength="100" value={formData.rf_address} name="rf_address" label="Referencing Address" placeholder="Referencing Address" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} ></textarea>
                                                    <span className="text-danger">{errorFormData.rf_address}</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Vuf Referencing Given Name</label>

                                                    <input className="form-control" maxLength="50" value={formData.vuf_rf_name} name="vuf_rf_name" label="Vuf Referencing Given Name" placeholder="Vuf Referencing Given Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.vuf_rf_name}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Vuf Referencing Mobile Number</label>
                                                    <input className="form-control" maxLength="10" value={formData.vuf_rf_mobile} name="vuf_rf_mobile" label="Vuf Referencing Mobile Number" placeholder="Vuf Referencing Mobile Number" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.vuf_rf_mobile}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Vuf Referencing Address</label>

                                                    <textarea className="form-control" maxLength="100" value={formData.vuf_rf_address} name="vuf_rf_address" label="Vuf Referencing Address" placeholder="Vuf Referencing Address" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} ></textarea>
                                                    <span className="text-danger">{errorFormData.vuf_rf_address}</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Local Vuf Referencing Given Name</label>

                                                    <input className="form-control" maxLength="50" value={formData.local_vuf_volunteer_name_1} name="local_vuf_volunteer_name_1" label="Local Vuf Referencing Given Name" placeholder="Local Vuf Referencing Given Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.local_vuf_volunteer_name_1}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Local Vuf Referencing Mobile Number</label>
                                                    <input className="form-control" maxLength="10" value={formData.local_vuf_mobile_1} name="local_vuf_mobile_1" label="Local Vuf Referencing Mobile Number" placeholder="Local Vuf Referencing Mobile Number" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.local_vuf_mobile_1}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Local Vuf Referencing Address</label>
                                                    <textarea className="form-control" maxLength="100" value={formData.local_vuf_address_1} name="local_vuf_address_1" label="Local Vuf Referencing Address" placeholder="Local Vuf Referencing Address" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} ></textarea>
                                                    <span className="text-danger">{errorFormData.local_vuf_address_1}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Local Vuf Referencing Given Name</label>
                                                    <input className="form-control" maxLength="50" value={formData.local_vuf_volunteer_name_2} name="local_vuf_volunteer_name_2" label="Local Vuf Referencing Given Name" placeholder="Local Vuf Referencing Given Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.local_vuf_volunteer_name_2}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Local Vuf Referencing Mobile Number</label>
                                                    <input className="form-control" maxLength="10" value={formData.local_vuf_mobile_2} name="local_vuf_mobile_2" label="Local Vuf Referencing Mobile Number" placeholder="Local Vuf Referencing Mobile Number" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.local_vuf_mobile_2}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="control-label">Local Vuf Referencing Address</label>
                                                    <textarea className="form-control" maxLength="100" value={formData.local_vuf_address_2} name="local_vuf_address_2" label="Local Vuf Referencing Address" placeholder="Local Vuf Referencing Address" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} ></textarea>
                                                    <span className="text-danger">{errorFormData.local_vuf_address_2}</span>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" className="">Add Divangat</Button>{" "}
                                        </div>
                                    </form>



                                    {divangat_id ?
                                        <div className="mb-4">
                                            <div className="head-text mb-4"> Document Information </div>

                                            <div className="d-flex flex-wrap gap-2 mb-4">
                                                <Button type="button" color="primary" className="mb-4" onClick={() => setmodal_xlarge(true)} className="">Upload Document</Button>{" "}
                                            </div>
                                            <Modal size="xl" isOpen={modal_xlarge} toggle={() => { setmodal_xlarge(true) }}>
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">Upload Document</h5>
                                                    <button onClick={() => { setmodal_xlarge(false) }} type="button" className="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form onSubmit={e => { handleDocumentSubmit(e) }}>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="formbold-mb-5 formbold-file-input">
                                                                    <label>
                                                                        <input type="file" accept="image/png,image/jpg,image/jpeg" name="document" onChange={e => uploadDocument(e)} id="file" />
                                                                        <div>
                                                                            <span className="formbold-drop-file"> Receipt File here </span>
                                                                            <span className="formbold-or"> Or </span>
                                                                            <span className="formbold-browse"> Browse </span>
                                                                        </div>
                                                                    </label>
                                                                    <span className="text-danger">{errorDocumentFormData.document}</span>

                                                                </div>
                                                            </div>
                                                            {imgData ?
                                                                <div className="col-md-4">
                                                                    <div className="formbold-mb-5 formbold-file-input">
                                                                        <img className="playerProfilePic_home_tile" height="150" width="150" src={imgData} />

                                                                    </div>
                                                                </div>
                                                                : ""}
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-3">
                                                                <div className="mb-3">
                                                                    <label className="control-label">Title</label>
                                                                    <input className="form-control" name="title" onChange={e => updateDocumentData(e)}
                                                                        onBlur={e => updateDocumentData(e)} placeholder="Title" type="text" />
                                                                    <span className="text-danger">{errorDocumentFormData.title}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="mb-3">
                                                                    <label className="control-label">Note</label>
                                                                    <input className="form-control" name="note" onChange={e => updateDocumentData(e)}
                                                                        onBlur={e => updateDocumentData(e)} placeholder="Note" type="text" />
                                                                    <span className="text-danger">{errorDocumentFormData.note}</span>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className="d-flex flex-wrap gap-2">
                                                            <Button type="submit" color="primary" className="">Upload Document</Button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Modal>


                                            {documentView && documentView.length ?
                                                <div>
                                                    <DocumentList document={documentView} divangat_id={divangat_id} />
                                                </div>
                                                : ""}

                                        </div>
                                        : ""}



                                    {divangat_id && formData.approval_status ?
                                        <div>
                                            <div className="head-text mb-4"> Payment Information </div>
                                            <div className="d-flex flex-wrap gap-2 mb-4">
                                                <Button type="button" color="primary" className="mb-4" onClick={() => setmodalpayment_xlarge(true)} className="">Upload Payment</Button>{" "}
                                            </div>
                                            <Modal size="xl" isOpen={modalpayment_xlarge} toggle={() => { setmodalpayment_xlarge(true) }}>
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">Upload Document</h5>
                                                    <button onClick={() => { setmodalpayment_xlarge(false) }} type="button" className="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <form onSubmit={e => { handlePaymentSubmit(e) }}>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="formbold-mb-5 formbold-file-input">
                                                                    <label>
                                                                        <input type="file" accept="image/png,image/jpg,image/jpeg" name="document" onChange={e => uploadPaymentReceipt(e)} id="file" />
                                                                        <div>
                                                                            <span className="formbold-drop-file"> Receipt File here </span>
                                                                            <span className="formbold-or"> Or </span>
                                                                            <span className="formbold-browse"> Browse </span>
                                                                        </div>
                                                                    </label>
                                                                    <span className="text-danger">{errorPaymentFormData.document}</span>

                                                                </div>
                                                            </div>
                                                            {imgDataPayment ?
                                                                <div className="col-md-4">
                                                                    <div className="formbold-mb-5 formbold-file-input">
                                                                        <img className="playerProfilePic_home_tile" height="150" width="150" src={imgDataPayment} />

                                                                    </div>
                                                                </div>
                                                                : ""}
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-3">
                                                                <div className="mb-3">
                                                                    <label className="control-label">Amount</label>
                                                                    <input className="form-control" name="amount" onChange={e => updatePaymentData(e)}
                                                                        onBlur={e => updatePaymentData(e)} placeholder="Amount" type="text" />
                                                                    <span className="text-danger">{errorPaymentFormData.amount}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="mb-3">
                                                                    <label className="control-label">Payment Note</label>
                                                                    <input className="form-control" name="payment_note" onChange={e => updatePaymentData(e)}
                                                                        onBlur={e => updatePaymentData(e)} placeholder="Payment Note" type="text" />
                                                                    <span className="text-danger">{errorPaymentFormData.payment_note}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="mb-3">
                                                                    <label className="control-label">Payment Type</label>
                                                                    <select className="form-control" name="payment_type" onChange={e => updatePaymentData(e)}>
                                                                        {PaymentType.map((res, i) => {
                                                                            return <option key={i} value={res.value}>{res.label}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="mb-3">
                                                                    <Label>Payment Date</Label>
                                                                    <InputGroup>
                                                                        <Flatpickr className="form-control d-block" placeholder="dd M,yyyy" name="payment_date"
                                                                            onChange={e => updatePaymentDateSubmitForm(e)}
                                                                            onBlur={e => updatePaymentDateSubmitForm(e)}
                                                                            options={{ altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d" }} />
                                                                    </InputGroup>
                                                                    <span className="text-danger">{errorPaymentFormData.payment_date}</span>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className="d-flex flex-wrap gap-2">
                                                            <Button type="submit" color="primary" className="">Upload Payment</Button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Modal>
                                            {paymentView && paymentView.length ?
                                                <div>
                                                    <PaymentListDivangat payment={paymentView} divangat_id={divangat_id} />
                                                </div>
                                                : ""}
                                        </div>
                                        : ""}
                                </CardBody>
                            </Card>
                        </Col>


                    </Row >
                </Container >
            </div >
        </React.Fragment >
    )
}

export default AddDivangat
