import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import Lightbox from "react-image-lightbox"

const ImageView = ({ formDataUploads, viewForm }) => {
    let [imagePreview, setImagePreview] = useState({
        currentAttachment: null,
        isFits: false
    })
    function assignPhotostate(imageUrl, folder_name) {
        setImagePreview({
            isFits: true,
            currentAttachment: imageUrl
        })
    }
    function removePhotostate() {
        setImagePreview({
            isFits: false,
            currentAttachment: null
        })
    }
    useEffect(() => {
    }, [])
    return (
        <React.Fragment>
            {imagePreview.isFits ? (
                <Lightbox
                    mainSrc={imagePreview.currentAttachment}
                    enableZoom={false}
                    onCloseRequest={() => { removePhotostate() }} />
            ) : null}
            <div className="">
                <div className="container-fluid">
                    <Row>
                        <Col className="col-12">
                            <div className="row mb-3">
                                {formDataUploads.user_images && !viewForm ?
                                    <div className={viewForm ? "col-md-2" : "col-md-2"}>
                                        <div className="formbold-mb-5">
                                            <label className="control-label w-100 text-center">User Image</label>
                                            <div>
                                                <img onClick={() => { assignPhotostate(formDataUploads.user_images, process.env.REACT_APP_IMAGEPATH) }} className="img-fluid uploads" alt="User-Image" src={formDataUploads.user_images} crossorigin="anonymous" />
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                }

                                {formDataUploads.user_aadhar_cad ?
                                    <div className={viewForm ? "col-md-3" : "col-md-2"}>
                                        <div className="formbold-mb-5">
                                            <label className="control-label w-100 text-center">Aadhar Image</label>
                                            <div>
                                                <img onClick={() => { assignPhotostate(formDataUploads.user_aadhar_cad, process.env.REACT_APP_AADHAR) }} className="img-fluid uploads" alt="User-Aadhar-Card" src={formDataUploads.user_aadhar_cad} crossorigin="anonymous" />
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                {formDataUploads.user_pan_card ?
                                    <div className={viewForm ? "col-md-3" : "col-md-2"}>
                                        <div className="formbold-mb-5">
                                            <label className="control-label w-100 text-center">Pan Image</label>
                                            <div>
                                                <img onClick={() => { assignPhotostate(formDataUploads.user_pan_card, process.env.REACT_APP_PAN) }} className="img-fluid uploads" alt="User-Pan-Card" src={formDataUploads.user_pan_card} crossorigin="anonymous"/>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                {formDataUploads.user_cheque ?
                                    <div className={viewForm ? "col-md-3" : "col-md-2"}>
                                        <div className="formbold-mb-5">
                                            <label className="control-label w-100 text-center">Cheque Image</label>
                                            <div>
                                                <img onClick={() => { assignPhotostate(formDataUploads.user_cheque, process.env.REACT_APP_CANCELCHEQUE) }} className="img-fluid uploads" alt="User-Check" src={formDataUploads.user_cheque} crossorigin="anonymous"/>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                {formDataUploads.user_signechar ?
                                    <div className={viewForm ? "col-md-3" : "col-md-2"}>
                                        <div className="formbold-mb-5">
                                            <label className="control-label w-100 text-center">Signature Image</label>
                                            <div>
                                                <img onClick={() => { assignPhotostate(formDataUploads.user_signechar, process.env.REACT_APP_SIGNATURE) }} className="img-fluid uploads" alt="User-Signature" src={formDataUploads.user_signechar} crossorigin="anonymous"/>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}


                            </div>
                        </Col>
                    </Row>

                </div>

            </div>
        </React.Fragment>
    )
}

export default ImageView
