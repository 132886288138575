import React from "react";
import API from '../../api';
import DatatableTables from "components/Common/Tables/DatatableTables";
import SweetAlert from "react-bootstrap-sweetalert"
import ToastMessage from 'components/Common/toastar';
import { Link } from 'react-router-dom';
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";
import { Button, Modal } from "reactstrap";
import logo from "../../assets/images/vishvUmiya.png";

const ref = React.createRef();

export class UsersList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            columns: null,
            error: null,
            selectedUsers: null,
            confirm_alert: false,
            isAddMember: false,
            isViewMember: false,
            currentAddMemberID: null,
            usersAssignedMemberListRows: null,
            usersAssignedMemberListColumns: null

        }
    }



    componentDidMount() {
        this.getAllUsers();
    }

    componentDidUpdate() {
    }

    deletePost(index) {

    }

    removeOrHideModal() {
        this.setState(prevState => ({ ...prevState, confirm_alert: false }))
    }

    removememberFromUser() {
        let data = {
            user_id: this.state.selectedUsers.user_id,
            member_id: this.state.selectedUsers.member_id
        }
        API.post('user_remove_member', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message });
                this.getAllMembersFromUserID(this.state.selectedUsers)
                this.setState(prevState => ({ ...prevState, confirm_alert: false }))

            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    getAllUsers() {
        let columns = [
            { label: 'Name', field: 'full_name', sort: 'asc', width: 150 },
            { label: 'Email', field: 'email', sort: 'asc', width: 80 },
            { label: 'Mobile Number', field: 'mobileNo', sort: 'asc', width: 150 },
            { label: 'Account Type', field: 'accountType', sort: 'asc', width: 200 },
            { label: 'View Member', field: 'view_member', sort: 'asc', width: 150 },
            { label: 'Add Member', field: 'add_member', sort: 'asc', width: 150 },
        ]
        API.get('user_list').then(res => {
            if (res.data.status === 1) {
                let rows = [];
                res.data.data.map(users => {
                    rows.push({
                        ...users,
                        full_name: users.firstName + ' ' + users.lastName,
                        view_member: <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button color="primary" onClick={() => this.assignUserDataAndGetMembers(users)}> View Member </Button>
                        </div>,
                        add_member:
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Button color="success" onClick={() => this.setState((prevState) => { return { ...prevState, isAddMember: true, selectedUsers: users } })}> Add Member </Button>
                            </div>
                    })
                })
                this.setState({ rows: rows, columns: columns })
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    };

    assignUserDataAndGetMembers(users) {
        this.setState((prevState) => { return { ...prevState, isViewMember: true, selectedUsers: users } });
        this.getAllMembersFromUserID(users)
    }


    getAllMembersFromUserID(usersres) {
        let columnsUser = [
            { label: 'Member ID', field: 'member_id', sort: 'asc', width: 150 },
            { label: 'Image', field: 'image_url', sort: 'asc', width: 150 },
            { label: 'Name', field: 'full_name', sort: 'asc', width: 150 },
            { label: 'Email', field: 'email', sort: 'asc', width: 80 },
            { label: 'Mobile Number', field: 'mobileNo', sort: 'asc', width: 150 },
            { label: 'Remove Member', field: 'remove_member', sort: 'asc', width: 150 },
        ]
        let data = {
            user_id: usersres.user_id
        }
        API.post('user_to_member', data).then(res => {
            if (res.data.status === 1) {
                let rowsUser = [];
                res.data.data.map(users => {
                    rowsUser.push({
                        user_id: usersres.user_id,
                        user_images: users.user_images,
                        member_id: users.member_id,
                        full_name: users.name + ' ' + users.surname_name,
                        email: users.email,
                        mobile_no_1: users.mobile_no_1
                    })
                })
                this.setState({ usersAssignedMemberListRows: rowsUser, usersAssignedMemberListColumns: columnsUser })
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }

    hideAddMemberModal = (contact) => {
        this.setState(prevState => ({ ...prevState, isAddMember: false, selectedUsers: contact }))
        this.removeBodyCss()
    }

    hideViewMemberModal = (contact) => {
        this.setState(prevState => ({ ...prevState, isViewMember: false, selectedUsers: contact }))
        this.removeBodyCss()
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    submitAddMember() {
        if (!this.state.selectedUsers || !this.state.currentAddMemberID) {
            ToastMessage({ type: 'error', message: 'Please add any member' })
            return;
        }
        let data = {
            user_id: this.state.selectedUsers.user_id,
            member_id: this.state.currentAddMemberID
        }

        API.post('user_add_member', data).then(res => {
            if (res.data.status === 1) {
                this.hideAddMemberModal();
                ToastMessage({ type: 'success', message: res.data.message })
            } else {
                this.hideAddMemberModal();
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            this.hideAddMemberModal();
            ToastMessage({ type: 'error', message: 'Internal Server Errore' })
        });
    }

    render() {
        return (
            <div>
                <Modal size="lg" isOpen={this.state.isAddMember}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                            Add Member For {this.state.selectedUsers ? this.state.selectedUsers.firstName + ' ' + this.state.selectedUsers.lastName : " "}
                        </h5>
                        <button onClick={() => { this.hideAddMemberModal(undefined) }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="row" ref={ref}>
                                <label className="mt-4">Member ID</label>
                                <div className="col-md-6">
                                    <input type="text" name="member_id" placeholder="Member ID" className="form-control" onChange={(e) => this.state.currentAddMemberID = e.target.value} />
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary waves-effect waves-light mt-4" onClick={() => this.submitAddMember()}>Add Member</button>
                        </form>
                    </div>
                </Modal>

                <Modal size="lg" isOpen={this.state.isViewMember}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                            Member Assigned To {this.state.selectedUsers ? this.state.selectedUsers.firstName + ' ' + this.state.selectedUsers.lastName : " "}
                        </h5>
                        <button onClick={() => { this.hideViewMemberModal(undefined) }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.usersAssignedMemberListRows != null ?
                            <table>
                                <thead>
                                    <tr>
                                        <th>Member ID</th>
                                        <th>Member Image</th>
                                        <th>Member Name</th>
                                        <th>Email</th>
                                        <th>Mobile Number</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                {this.state.usersAssignedMemberListRows.length ?
                                    this.state.usersAssignedMemberListRows.map((users, i) => {
                                        return (
                                            <tbody key={i}>
                                                <tr>
                                                    <td>{users.member_id}</td>
                                                    <td><img src={users.user_images ? users.user_images: logo} crossOrigin="anonymous" height="50" width="50" /></td>
                                                    <td>{users.full_name}</td>
                                                    <td>{users.email}</td>
                                                    <td>{users.mobile_no_1}</td>
                                                    <td>
                                                        <div className="uil-trash-alt ml-4" onClick={() => this.setState((prevState) => { return { ...prevState, confirm_alert: true, selectedUsers: users } })}> <i className="ti-trash"></i></div>
                                                    </td>
                                                </tr>
                                            </tbody>)
                                    }) : <tbody>
                                        <tr>
                                            <td className="text-center" colSpan="6">No Record Found</td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                            : <div className="text-center">No Record Found</div>}
                    </div>
                </Modal>
                <div >
                    {this.state.confirm_alert ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmButtonText="Yes, delete it!"
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                this.removememberFromUser()
                            }}
                            onCancel={() => this.removeOrHideModal()}
                        >
                            You won't be able to revert this!
                            </SweetAlert>
                    ) : null}
                    {this.state.rows != null ?
                        <DatatableTables title={'Users Details'} columns={this.state.columns} dataSource={this.state.rows} />
                        : ''}
                </div>

            </div>
        )
    }
}


export default UsersList;