import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { isOuterMenuShowFromRoles } from "common/decodedRole";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("auth-token")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        if (isAuthProtected && localStorage.getItem("auth-token") && window.atob(localStorage.getItem('user_token')) === 'volunteer') {
          if (!isOuterMenuShowFromRoles([props.match.path])) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
