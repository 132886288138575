import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import API from '../../api';
import { Row, Col, Card, CardBody, FormGroup, Button, CardTitle, CardSubtitle, Label, Input, Container, CustomInput } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import ToastMessage from 'components/Common/toastar';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ContributionType } from "common/list";
import { useHistory, useParams } from "react-router-dom";
import ChangeVolunteerPassword from "./changeVolunteerPassword";
import ChangeVolunteerStatus from "./changeVolunteerStatus";
import ChangeVolunteerAreaPinCode from "./changeVolunteerAreaPinCode";
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";

const optionGroup2 = ContributionType
const AddVolunteer = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobileNo: "",
        status: null,
        access_pin_code: null
    });
    const [errorFormData, errorSetFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobileNo: ""
    });
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showArePincodeModal, setShowArePincodeModal] = useState(false);
    const [decodedRole, setDecodedRole] = useState("Admin")

    const { id } = useParams()
    const history = useHistory();

    useEffect(() => {
        if (id) {
            getSingleVolunteerDetail();
            setDecodedRole(decodeRole());
        }
    }, [])

    const updateFormData = event => {
        errorSetFormData({
            ...errorFormData,
            [event.target.name]: validate(event.target.name, event.target.value)
        });

        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    function validate(name, value) {
        if (name === 'firstName' || name === 'lastName' || name === 'email' || name === 'mobileNo') {
            if (!value) {
                return "Please enter " + convertErrorMessage(name);
            } else {
                return "";
            }
        }

        if (!id) {
            if (name === 'password') {
                if (!value) {
                    return "Please enter " + convertErrorMessage(name);
                } else {
                    return "";
                }
            }
        }
        switch (name) {
            case "email":
                if (!value) {
                    return "Please enter email";
                } else if (
                    !value.match(/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i)
                ) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            case "mobileNo":
                if (value && (!value.match(/^[0-9]*$/i) || value.length > 10)) {
                    return "Enter a valid witness mobile number";
                } else {
                    return "";
                }

            default: {
                return "";
            }
        }
    }

    function convertErrorMessage(e) {
        return e.replace(/_/g, " ");
    }

    function getSingleVolunteerDetail() {
        let data = { volunteer_id: id }
        API.post('volunteerInfo', data).then(res => {
            if (res.data.status === 1) {
                let data = res.data.data;
                setFormData(data);
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        let validationErrors = {};
        Object.keys(formData).forEach(name => {
            const error = validate(name, formData[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            errorSetFormData(validationErrors);
            return;
        }
        if (id) {
            let data = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                mobileNo: formData.mobileNo,
                volunteer_id: id
            }
            API.post('editVolunteer', data).then(res => {
                if (res.data.status === 1) {
                    ToastMessage({ type: 'success', message: res.data.message })
                    if (decodedRole !== VOLUNTEER_ROLE) {
                        history.push('/volunteer-list');
                    }
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Internal Server Error' })

            });
        } else {
            let data = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                password: formData.password,
                mobileNo: formData.mobileNo,
            }
            API.post('addVolunteer', data).then(res => {
                if (res.data.status === 1) {
                    ToastMessage({ type: 'success', message: res.data.message });
                    if (decodedRole !== VOLUNTEER_ROLE) {
                        history.push('/volunteer-list');
                    }
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Internal Server Error' })

            });
        }
    }

    function changeVolunteerPassword() {
        setShowPasswordModal(true)
    }

    function changeAreaPinCode() {
        setShowArePincodeModal(true)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {id && showPasswordModal ? <ChangeVolunteerPassword volunteerId={id} onCloseData={() => setShowPasswordModal(false)} /> : ""}
                {id && showArePincodeModal ? <ChangeVolunteerAreaPinCode volunteerId={id} area_pin_code={formData.access_pin_code} onClosePinCodeData={() => { setShowArePincodeModal(false); getSingleVolunteerDetail() }} /> : ""}
                <MetaTags>
                    <title>{id ? 'Edit Volunteer' : 'Add Volunteer'}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Volunteer" breadcrumbItem={id ? 'Edit Volunteer' : 'Add Volunteer'} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="view-plan-container">
                                        <CardTitle>{id ? 'Edit Volunteer Information' : 'Add Volunteer Information'}</CardTitle>
                                        {id ?
                                            <div className="d-flex gap-2">
                                                {decodedRole !== VOLUNTEER_ROLE ?
                                                    <div className="d-flex gap-2">
                                                        <div className="mb-3 mr-5">
                                                            <div className="d-flex flex-wrap align-items-center gap-2">
                                                                <div className="control-label">Status</div>
                                                                {formData.status != null ? <ChangeVolunteerStatus volunteerId={id} status={formData.status} /> : ""}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    : ""}
                                                <div className="mb-3 ml-5">
                                                    <div className="d-flex flex-wrap gap-2">
                                                        <Button type="button" onClick={() => changeVolunteerPassword()} color="primary" className="" >Change Password</Button>
                                                    </div>
                                                </div>
                                                {decodedRole !== VOLUNTEER_ROLE ?
                                                    <div className="mb-3 ml-5">
                                                        <div className="d-flex flex-wrap gap-2">
                                                            <Button type="button" onClick={() => changeAreaPinCode()} color="primary" className="" >Add Area Pin Code</Button>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>

                                            : ""}
                                    </div>
                                    <form onSubmit={e => { handleSubmit(e) }}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">First Name</label>
                                                    <input name="firstName" className="form-control" maxLength="30" value={formData.firstName} label="First Name" placeholder="First Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.firstName}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Last Name</label>
                                                    <input className="form-control" maxLength="30" name="lastName" value={formData.lastName} label="Last Name" placeholder="Last Name" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.lastName}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Email</label>
                                                    <input className="form-control" maxLength="50" name="email" value={formData.email} label="Email" placeholder="Email" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.email}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <label className="control-label">Mobile No</label>
                                                    <input className="form-control" maxLength="10" name="mobileNo" value={formData.mobileNo} label="Mobile Number" placeholder="Mobile Number" type="text" onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)} />
                                                    <span className="text-danger">{errorFormData.mobileNo}</span>
                                                </div>
                                            </div>

                                            {!id ?
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <label className="control-label">Password</label>
                                                        <input className="form-control" maxLength="50" name="password" value={formData.password} label="Password" placeholder="Password" type="text" onChange={e => updateFormData(e)}
                                                            onBlur={e => updateFormData(e)} />
                                                        <span className="text-danger">{errorFormData.password}</span>
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                        {id && formData.access_pin_code && formData.access_pin_code.length ?
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <label className="control-label">Area Pin Code</label>
                                                    <div className="d-flex flex-wrap gap-2">
                                                        {formData.access_pin_code.split(',').map((res, index) => {
                                                            return <span key={index} className="p-2 bg-primary badge badge-secondary">{res}</span>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <div className="d-flex flex-wrap gap-2">
                                                        <Button type="submit" color="primary" className="" >Submit</Button>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddVolunteer
