import React from "react";
import API from '../../api';
import DatatableTables from "components/Common/Tables/DatatableTables";
import SweetAlert from "react-bootstrap-sweetalert"
import ToastMessage from 'components/Common/toastar';
import { Link } from 'react-router-dom';
import NotFound from "components/Common/notFound";
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";
import ChangeDivangatStatus from "./changeDivangat";

export class DivangatList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            columns: null,
            rowsForVerified: null,
            columnsForVerified: null,
            error: null,
            selectedDivangat: null,
            selectedDivangatVerified: null,
            confirm_alert_verified: false,
            confirm_alert: false,
            type: props.match.params.id,
        }
    }

    componentDidMount() {
        if (this.state.type === 'unverified') {
            this.getAllDivangatsUnverified();

        } else {
            this.getAllDivangatsVerified();
        }
    }

    deleteDivangat(index) {
        this.setState(prevState => ({ ...prevState, confirm_alert: true, selectedDivangat: index }))
    }

    deleteDivangatVerified(index) {
        this.setState(prevState => ({ ...prevState, confirm_alert_verified: true, selectedDivangatVerified: index }))
    }

    removeDivangatFromMember(isVerified) {
        let data = {
            divangat_id: isVerified ? this.state.selectedDivangatVerified : this.state.selectedDivangat
        }
        API.post('deletedDivangat', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message })
                if (isVerified) {
                    this.setState(prevState => ({ ...prevState, confirm_alert_verified: false, selectedDivangatVerified: null }))
                    this.getAllDivangatsVerified();
                } else {
                    this.setState(prevState => ({ ...prevState, confirm_alert: false, selectedDivangat: null }));
                    this.getAllDivangatsUnverified();
                }
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    getAllDivangatsUnverified() {
        let columns = [
            { label: 'Divangat ID', field: 'divangat_id', sort: 'asc', width: 150 },
            { label: 'Name', field: 'divangat_name', sort: 'asc', width: 150 },
            { label: 'Mobile Number', field: 'mobile_no_1', sort: 'asc', width: 150 },
            { label: 'Pin code', field: 'pin_code', sort: 'asc', width: 200 },
            { label: 'Payment Amount', field: 'payment_amount', sort: 'asc', width: 130 },
            { label: 'Payment Type', field: 'payment_type', sort: 'asc', width: 130 },
            { label: 'Action', field: 'action', sort: 'asc', width: 150 }
        ]
        API.get('unapprovaDivangatList').then(res => {
            if (res.data.status === 1) {
                let rows = [];
                res.data.data.map(divangat => {
                    rows.push({
                        ...divangat,
                        divangat_name: divangat.name + ' ' + divangat.surname_name,
                        payment_amount: divangat.plan_info.plans_amount,
                        payment_type: divangat.plan_info.plans_payment_type,
                        action:
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="uil-edit-alt"> <Link to={`/add-divangat/${divangat.divangat_id}`}><i className="ti-pencil-alt"></i></Link>
                                </div>
                                {/* <div className="uil-edit-alt ml-4"> <Link to={`/view-member/${member.divangat_id}`}><i className="ti-receipt"></i> </Link>
                                </div> */}
                                {decodeRole() !== VOLUNTEER_ROLE ?
                                    <div className="uil-edit-alt ml-4" onClick={() => this.deleteDivangat(divangat.divangat_id)}> <i className="ti-trash"></i></div>
                                    : ""}

                            </div>
                    })
                });
                this.setState(prevState => ({ ...prevState, rows: rows, columns: columns }))
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }

    getAllDivangatsVerified() {
        let columns = [
            { label: 'Divangat ID', field: 'member_id', sort: 'asc', width: 150 },
            { label: 'Name', field: 'divangat_name', sort: 'asc', width: 150 },
            { label: 'Mobile Number', field: 'mobile_no_1', sort: 'asc', width: 150 },
            { label: 'Pin code', field: 'pin_code', sort: 'asc', width: 200 },
            { label: 'Payment Amount', field: 'payment_amount', sort: 'asc', width: 130 },
            { label: 'Payment Type', field: 'payment_type', sort: 'asc', width: 130 },
            { label: 'Action', field: 'action', sort: 'asc', width: 150 }
        ]
        API.get('approvaDivangatList').then(res => {
            if (res.data.status === 1) {
                let rows = [];
                res.data.data.map(divangat => {
                    rows.push({
                        ...divangat,
                        divangat_name: divangat.name + ' ' + divangat.surname_name,
                        payment_amount: divangat.plan_info.plans_amount,
                        payment_type: divangat.plan_info.plans_payment_type,
                        action:
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="uil-edit-alt"> <Link to={`/add-divangat/${divangat.divangat_id}`}><i className="ti-pencil-alt"></i></Link>
                                </div>
                                {/* <div className="uil-edit-alt ml-4"> <Link to={`/view-member/${member.divangat_id}`}><i className="ti-receipt"></i></Link>
                                </div> */}
                                {decodeRole() !== VOLUNTEER_ROLE ?
                                    <div className="uil-trash-alt ml-4" onClick={() => this.deleteDivangatVerified(divangat.divangat_id)}><i className="ti-trash"></i></div>
                                    : ""}
                            </div>
                    })
                });
                this.setState(prevState => ({ ...prevState, rowsForVerified: rows, columnsForVerified: columns }))
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }

    render() {
        return (
            <div>
                <div>
                    {this.state.confirm_alert ? (
                        <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, delete it!" confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => { this.removeDivangatFromMember(false) }}
                            onCancel={() => this.setState(prevState => ({ ...prevState, confirm_alert: false }))
                            }>You won't be able to revert this!</SweetAlert>
                    ) : null}
                    {this.state.confirm_alert_verified ? (
                        <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, delete it!" confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => { this.removeDivangatFromMember(true) }}
                            onCancel={() => this.setState(prevState => ({ ...prevState, confirm_alert_verified: false }))
                            }>You won't be able to revert this!</SweetAlert>
                    ) : null}
                    {this.state.type === 'unverified' ?
                        this.state.rows != null ?
                            <DatatableTables title={'Unverified Divangat Details'} columns={this.state.columns} dataSource={this.state.rows} />
                            : <NotFound />
                        : ""}
                    {this.state.type !== 'unverified' ?
                        this.state.rowsForVerified != null ?
                            <DatatableTables title={'Verified Divangat Details'} columns={this.state.columnsForVerified} dataSource={this.state.rowsForVerified} />
                            : <NotFound />
                        : ""}
                </div>
            </div>
        )
    }
}


export default DivangatList;