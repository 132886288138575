import React from "react";
import API from '../../api';
import DatatableTables from "components/Common/Tables/DatatableTables";
import SweetAlert from "react-bootstrap-sweetalert"
import ToastMessage from 'components/Common/toastar';
import { Link } from 'react-router-dom';
import NotFound from "components/Common/notFound";
import ChangeVolunteerStatus from "./changeVolunteerStatus";

export class VolunteerList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            columns: null,
            error: null,
            selectedVolunteer: null,
            confirm_alert: false,
        }
    }

    componentDidMount() {
        this.getAllVolunteerList();
    }

    deleteVolunteer(index) {
        this.setState(prevState => ({ ...prevState, confirm_alert: true, selectedVolunteer: index }))
    }

    removeVolunteer() {
        let data = {
            volunteer_id: this.state.selectedVolunteer
        }
        API.post('volunteerDelete', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message })
                this.setState(prevState => ({ ...prevState, confirm_alert: false, selectedVolunteer: null }));
                this.getAllVolunteerList();
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    getAllVolunteerList() {
        let columns = [
            { label: 'Volunteer Name', field: 'name', sort: 'asc', width: 150 },
            { label: 'Mobile Number', field: 'mobileNo', sort: 'asc', width: 150 },
            { label: 'Email', field: 'email', sort: 'asc', width: 150 },
            { label: 'Pin code', field: 'access_pin_code', sort: 'asc', width: 200 },
            { label: 'Status', field: 'status', sort: 'asc', width: 200 },
            { label: 'Action', field: 'action', sort: 'asc', width: 50 }
        ]
        API.get('volunteerList').then(res => {
            if (res.data.status === 1) {
                let rows = [];
                res.data.data.map(volunteer => {
                    rows.push({
                        ...volunteer,
                        name: volunteer.firstName + ' ' + volunteer.lastName,
                        status: <div><ChangeVolunteerStatus volunteerId={volunteer.volunteer_id} status={volunteer.status} /></div>,
                        action:
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div className="uil-edit-alt"> <Link to={`/edit-volunteer/${volunteer.volunteer_id}`}><i className="ti-pencil-alt"></i></Link>
                                </div>
                                <div className="uil-edit-alt ml-1" onClick={() => this.deleteVolunteer(volunteer.volunteer_id)}> <i className="ti-trash"></i></div>

                            </div>
                    })
                });
                this.setState(prevState => ({ ...prevState, rows: rows, columns: columns }))
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }



    render() {
        return (
            <div>
                <div>
                    {this.state.confirm_alert ? (
                        <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, delete it!" confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => { this.removeVolunteer() }}
                            onCancel={() => this.setState(prevState => ({ ...prevState, confirm_alert: false }))
                            }>You won't be able to revert this!</SweetAlert>
                    ) : null}
                    {this.state.rows != null ?
                        <DatatableTables title={'Volunteer Details'} columns={this.state.columns} dataSource={this.state.rows} />
                        : <NotFound />
                    }
                </div>
            </div>
        )
    }
}


export default VolunteerList;