import React, { useEffect } from "react";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const ToastMessage = ({type, message}) => {
    toastr.options = {
        positionClass: 'toast-top-full-width',
        hideDuration: 300,
        timeOut: 60000
    }
    toastr.clear()
    if (type === 'success') {
        setTimeout(() => toastr.success(message), 300)
    } else {
        setTimeout(() => toastr.error(message), 300)
    }
}

export default ToastMessage;