import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react";
import API from '../../api';


//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  const [menu, setMenu] = useState(false);
  let [countMember, setCountMember] = useState(false);
  let [planAndMember, setPlanAndMember] = useState(false);

  const toggle = () => {
    setMenu(!menu)
  }

  useEffect(() => {
    getDashboardInfo();
  }, []);

  function getDashboardInfo() {
    API.get('admin_home_report', {}).then(res => {
      if (res.data && res.data.status) {
        setCountMember(res.data.data.html1);
        setPlanAndMember(res.data.data.html2)
      } else {
      }
    }).catch(error => {
    });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | UmaChattra</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                {/* <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to Veltrix Dashboard</li>
                </ol> */}
              </Col>

              {/* <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Dropdown isOpen={menu} toggle={toggle}>
                    <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">
                      <i className="mdi mdi-cog me-2"></i> Settings
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag="a" href="#">Action</DropdownItem>
                      <DropdownItem tag="a" href="#">Another action</DropdownItem>
                      <DropdownItem tag="a" href="#">Something else here</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag="a" href="#">Separated link</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Col> */}
            </Row>

            <Row>
              <h3 className="text-center"> Welcome To UmaChattra Dashboard</h3>
              <div className="counter home-section section-1 container">

                <div className="row">
                  <div dangerouslySetInnerHTML={{ __html: countMember }}></div>
                </div>
                <div className="row">
                  <div dangerouslySetInnerHTML={{ __html: planAndMember }}></div>
                </div>
              </div>
            </Row>

          </div>
        </Container>
      </div>

    </React.Fragment >
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
