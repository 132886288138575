import React from "react";
import API from '../../api';
import DatatableTables from "components/Common/Tables/DatatableTables";
import NotFound from "components/Common/notFound";
import LightBoxView from "components/Common/lightBoxView";
import { PaymentType, PaymentListFilters } from "common/list";
import Select from "react-select"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Flatpickr from "react-flatpickr"
import Lightbox from "react-image-lightbox"
import AddPayment from "./addPayment";
import "react-image-lightbox/style.css";
import { Link } from 'react-router-dom';
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";
import SweetAlert from "react-bootstrap-sweetalert"
import ToastMessage from 'components/Common/toastar';


export class PendingPaymentList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            columns: null,
            error: null,
            selectedPayment: null,
            confirm_alert: false,
            isFits: false,
            currentAttachment: null,
            filteredPaymentType: null,
            allRecords: null,
            customDate: null,
            customEndDate: null,
            showDateRangePicker: false,
            selectedMember: null
        }
    }

    deleteMember(index) {
        this.setState(prevState => ({ ...prevState, confirm_alert: true, selectedMember: index }))
    }

    componentDidMount() {
        this.getAllPaymentDetails();
    }

    getAllPaymentDetails = () => {
        let columns = [
            { label: 'Member ID', field: 'member_id', sort: 'asc', width: 150 },
            { label: 'Name', field: 'member_name', sort: 'asc', width: 150 },
            { label: 'Mobile Number', field: 'mobile_no_1', sort: 'asc', width: 200 },
            { label: 'Pin', field: 'pin_code', sort: 'asc', width: 130 },
            { label: 'State', field: 'state', sort: 'asc', width: 200 },
            { label: 'District', field: 'district', sort: 'asc', width: 200 },
            { label: 'City', field: 'city', sort: 'asc', width: 200 },
            { label: 'Amount', field: 'payment_amount', sort: 'asc', width: 130 },
            { label: 'Payment Type', field: 'payment_type', sort: 'asc', width: 130 },
            { label: 'Action', field: 'action', sort: 'asc', width: 150 }

        ]
        API.get('list_all_payment_pending').then(res => {
            if (res.data.status === 1) {
                let rows = [];
                res.data.data.map(payment => {
                    rows.push({
                        ...payment,
                        member_name: payment.name + ' ' + payment.surname_name,
                        payment_amount: payment.plan_info.plans_amount,
                        payment_type: payment.plan_info.plans_payment_type,
                        plan_name: (payment.plan_info && payment.plan_info) ? payment.plan_info.plan_name : '',
                        plans_amount: (payment.plan_info && payment.plan_info) ? payment.plan_info.plans_amount : '',
                        action:
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="uil-edit-alt"> <Link to={`/add-member/${payment.member_id}`}><i className="ti-pencil-alt"></i></Link>
                                </div>
                                <div className="uil-edit-alt ml-4"> <Link to={`/view-member/${payment.member_id}`}><i className="ti-receipt"></i></Link>
                                </div>
                                {decodeRole() !== VOLUNTEER_ROLE ?
                                    <div className="uil-trash-alt ml-4" onClick={() => this.deleteMember(payment.member_id)}><i className="ti-trash"></i></div>
                                    : ""}
                            </div>
                    })
                })
                this.setState(prevState => ({ ...prevState, rows: rows, allRecords: rows, columns: columns }))
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }

    assignPhotostate = imageUrl => {
        this.setState(prevState => ({ ...prevState, isFits: true, currentAttachment: imageUrl }))
    };

    remvovePhotostate = imageUrl => {
        this.setState(prevState => ({ ...prevState, isFits: false, currentAttachment: null }))
    };


    filteredPaymentData = value => {
        let selectedPayments = value ? value.map(res => res.value) : null;
        this.state.filteredPaymentType = selectedPayments;
        this.filteredSearchFromSelected();
    }

    filteredPaymentDateData = value => {
        this.setState(prevState => ({ ...prevState, showDateRangePicker: false }))
        this.state.customEndDate = new Date();
        let date = new Date();
        if (value.value == "Today") {
            this.state.customDate = new Date();
            this.state.customEndDate = false
        } else if (value.value == "Yesterday") {
            this.state.customDate = date.setDate(date.getDate() - 1);
            this.state.customEndDate = false;
        } else if (value.value == "Last 7 Days") {
            this.state.customDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
        } else if (value.value == "Last 30 Days") {
            this.state.customDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30);
        } else if (value.value == "Last Month") {
            this.state.customDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            this.state.customEndDate = new Date(date.getFullYear(), date.getMonth(), 0);
        } else if (value.value == "This Month") {
            this.state.customDate = new Date(date.getFullYear(), date.getMonth(), 1);
        } else if (value.value == "Custom Range") {
            this.setState(prevState => ({ ...prevState, showDateRangePicker: true }))
            return;
        } else {
            this.state.customDate = false;
        }
        this.filteredSearchFromSelected();
    }

    assignDateRange = e => {
        if (e.target.value) {
            let range = e.target.value.split('to')
            if (range.length >= 2) {
                this.state.customDate = range[0].trim();
                this.state.customEndDate = range[1].trim();
                this.filteredSearchFromSelected();
            }
        }
    }

    filteredSearchFromSelected = () => {
        let filteredPayment = [];
        if (!this.state.filteredPaymentType || !this.state.filteredPaymentType.length) {
            filteredPayment = this.state.allRecords
        }
        if (this.state.allRecords) {
            if (this.state.filteredPaymentType && this.state.filteredPaymentType.length) {
                filteredPayment = this.state.allRecords.filter(res =>
                    this.state.filteredPaymentType && this.state.filteredPaymentType.length ? this.state.filteredPaymentType.includes(res.payment_type) : false
                )
            }
            if (this.state.customDate && !this.state.customEndDate) {
                filteredPayment = filteredPayment.filter(res =>
                    this.state.customDate ? new Date(this.state.customDate).getTime() === new Date(res.payment_date).getTime() : false
                )
            }
            if (this.state.customDate && this.state.customEndDate) {
                filteredPayment = filteredPayment.filter(res =>
                    this.state.customDate ? this.checkDateInBetween(res.payment_date) : false
                )
            }
        } else {
            filteredPayment = this.state.allRecords;
        }
        this.setState(prevState => ({ ...prevState, rows: filteredPayment }))
    }

    checkDateInBetween = date => {
        var dateFrom = "02/05/2013";
        var dateTo = "02/09/2013";
        var dateCheck = date;

        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("-");

        var from = new Date(this.state.customDate).getTime()  // -1 because months are from 0 to 11
        var to = new Date(this.state.customEndDate).getTime();
        var check = new Date(Number(c[2]), parseInt(c[1]) - 1, Number(c[0])).getTime();
        return check > from && check < to
    }

    removeMemberFromMember() {
        let data = {
            member_id: this.state.selectedMember
        }
        API.post('delete_member', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message })
                this.setState(prevState => ({ ...prevState, confirm_alert: false, selectedMember: null }));
                this.getAllPaymentDetails();
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }



    render() {
        return (
            <div>

                <React.Fragment>
                    {this.state.confirm_alert ? (
                        <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, delete it!" confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => { this.removeMemberFromMember() }}
                            onCancel={() => this.setState(prevState => ({ ...prevState, confirm_alert: false }))
                            }>You won't be able to revert this!</SweetAlert>
                    ) : null}
                    {this.state.isFits ?
                        <Lightbox
                            mainSrc={this.state.currentAttachment}
                            enableZoom={false}
                            onCloseRequest={() => { this.remvovePhotostate() }} />
                        : ""}
                    {/* <div className="pb-0 mb-0"> */}
                    {/* <MetaTags>
                            <title>Donation List</title>
                        </MetaTags> */}
                    {/* <Container fluid={true}> */}
                    {/* <Breadcrumbs title="Donation" breadcrumbItem="Donation List" /> */}
                    {/* <Row>
                                <Col lg={12}>
                                    <Card>

                                        <CardBody>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="control-label"> Donation Type </label>
                                                    <Select isMulti={true} onChange={e => { this.filteredPaymentData(e) }} options={PaymentType} classNamePrefix="select2-selection" />
                                                </div>

                                                <div className="col-md-4 mb-3">
                                                    <label className="control-label"> Select Date </label>
                                                    <Select onChange={e => { this.filteredPaymentDateData(e) }} options={PaymentListFilters} classNamePrefix="select2-selection" />
                                                </div>
                                                {this.state.showDateRangePicker ?
                                                    <div className="col-md-4 mb-3">
                                                        <label className="control-label"> Date Range </label>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="dd M,yyyy"
                                                            onBlur={e => this.assignDateRange(e)}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "Y-m-d"
                                                            }}
                                                        />
                                                    </div>
                                                    : ""}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row> */}
                    {/* </Container> */}
                    {/* </div> */}

                    <div>

                        {this.state.rows != null ?
                            <DatatableTables title={'Pending Payment Details'} columns={this.state.columns} dataSource={this.state.rows} />
                            : <NotFound />
                        }
                        {/* <div className="">
                            <AddPayment onCloseData={(e) => this.getAllPaymentDetails()} />
                        </div> */}
                    </div>
                </React.Fragment >

            </div >
        )
    }
}


export default PendingPaymentList;