import React from "react";
import API from '../../api';
import DatatableTables from "components/Common/Tables/DatatableTables";
import NotFound from "components/Common/notFound";
import LightBoxView from "components/Common/lightBoxView";
import { PaymentType, PaymentListFilters } from "common/list";
import Select from "react-select"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Flatpickr from "react-flatpickr"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css";
const ref = React.createRef();
import ToastMessage from "components/Common/toastar";

export class ContactList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            columns: null,
            error: null,
            selectedContactReply: null,
            confirm_alert: false,
            isFits: false,
            allRecords: null,
            currentMessageReply: null
        }
    }

    componentDidMount() {
        this.getAllContactList();
    }

    getAllContactList = () => {
        let columns = [
            { label: 'Contact ID', field: 'contact_id', sort: 'asc', width: 150 },
            { label: 'Contact Name', field: 'contact_name', sort: 'asc', width: 150 },
            { label: 'Contact Mobile', field: 'mobileNo', sort: 'asc', width: 150 },
            { label: 'Email', field: 'email', sort: 'asc', width: 200 },
            { label: 'Message', field: 'user_message', sort: 'asc', width: 130 },
            { label: 'Reply Status', field: 'replay_status', sort: 'asc', width: 5 },
            { label: 'Reply', field: 'replay_message', sort: 'asc', width: 130 },
            { label: 'Action', field: 'action', sort: 'asc', width: 130 },
        ]
        API.get('contact_list').then(res => {
            if (res.data.status === 1) {
                let users = [];
                res.data.data.map(contact => {
                    let user_create_message = contact.message;
                    contact.custom_message = user_create_message;
                    delete contact.message
                    users.push({
                        ...contact,
                        user_message: user_create_message,
                        contact_name: contact.first_name + ' ' + contact.last_name,
                        replay_status: contact.replay_status ? 'Yes' : 'No',
                        action:
                            <div className="uil-edit-alt"> <i className="ti-pencil" onClick={() => this.showReplyContactModal(contact)}></i></div>

                    })
                })
                this.setState(prevState => ({ ...prevState, rows: users, allRecords: users, columns: columns }))
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }

    showReplyContactModal = (contact) => {
        this.setState(prevState => ({ ...prevState, isFits: true, selectedContactReply: contact }))
        this.removeBodyCss()
    }

    hideReplyContactModal = (contact) => {
        this.setState(prevState => ({ ...prevState, isFits: false, selectedContactReply: contact }))
        this.removeBodyCss()
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    tog_fullscreen() {
        this.setState(prevState => ({ ...prevState, isFits: !isFits, selectedContactReply: contact }))
        removeBodyCss()
    }

    submitReply() {
        if (this.state.selectedContactReply && !this.state.selectedContactReply.replay_message) {
            ToastMessage({ type: 'error', message: 'Please add reply message' })
            return;
        }
        let data = {
            contact_id: this.state.selectedContactReply.contact_id,
            replay_message: this.state.selectedContactReply.replay_message
        }

        API.post('replay_contact',data).then(res => {
            if (res.data.status === 1) {
                this.hideReplyContactModal();
                this.getAllContactList();
                ToastMessage({ type: 'success', message: res.data.message })
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            this.hideReplyContactModal();
            ToastMessage({ type: 'error', message: 'Internal Server Errore' })
            this.setState({ error: 'Internal Server Error' })
        });
    }

    render() {
        return (
            <div>

                <Modal
                    size="lg"
                    isOpen={this.state.isFits}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myExtraLargeModalLabel"
                        >
                            Contact Reply
                            </h5>
                        <button onClick={() => { this.hideReplyContactModal(undefined) }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {this.state.selectedContactReply ?
                        <div className="modal-body">
                            <form>
                                <div className="row" ref={ref}>
                                    <h4 className="contact-question"> User Message : {this.state.selectedContactReply.custom_message} </h4>
                                    <label className="mt-4">Reply Message </label>
                                    <div className="col-md-12 receipt-text-container">
                                        <textarea className="form-control" value={this.state.selectedContactReply.replay_message} onChange={(e) => this.state.selectedContactReply.replay_message = e.target.value} placeholder="Reply Message" id="replay_message" name="replay_message" ></textarea>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary waves-effect waves-light mt-4" onClick={() => this.submitReply()}>Submit Reply</button>
                            </form>
                        </div> :
                        ""}

                </Modal>
                {this.state.rows != null ?
                    <DatatableTables title={'Contact Details'} columns={this.state.columns} dataSource={this.state.rows} />
                    : <NotFound />
                }
            </div >
        )
    }
}


export default ContactList;