import React, { useState, useEffect } from "react"

import Switch from "react-switch"
import API from '../../api';
import ToastMessage from 'components/Common/toastar';

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
      </div>
    )
}

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
      </div>
    )
}


function ChangeVolunteerStatus({ volunteerId , status }) {

    const [switch1, setswitch1] = useState(true)


    useEffect(() => {
        setswitch1(!!status)
    }, [])


    function handleChange(e) {
        let data = {
            volunteer_id: volunteerId,
            status: e
        }
        API.post('volunteerStatusUpdate', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message });
                setswitch1(e)
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })

        });
    }

    return (
        <div className="App">

            <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                    handleChange(!switch1)
                }}
                checked={switch1}
            />
        </div >
    );
}

export default ChangeVolunteerStatus;
