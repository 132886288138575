import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom";
import { Row, Col, Card, CardBody, Modal, Button } from "reactstrap"
import API from '../../api';
import ToastMessage from 'components/Common/toastar';


function ChangeVolunteerPassword({ volunteerId, onCloseData }) {
    const [modal_standard, setmodal_standard] = useState(false)
    const [formData, setFormData] = useState({
        password: ""
    });
    const [passwordError, setPasswordError] = useState(false)

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        console.log(volunteerId)
        tog_standard()
    }, [])


    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    const updateFormData = event => {
        if (event.target.value) {
            setPasswordError(false)
        }
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!formData.password) {
            setPasswordError(true)
        }
        let data = {
            volunteer_id: volunteerId,
            password: formData.password
        }
        API.post('volunteerNewPassword', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message })
                onCloseData(false);
                setmodal_standard(false);
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })

        });
    }

    return (
        <div className="App">
            {/* <button type="button" onClick={() => { tog_xlarge() }}
                className="btn btn-primary waves-effect waves-light"
                data-toggle="modal"
                data-target=".bs-example-modal-xl"
            >Change Password</button> */}
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard()
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel"> Change Password</h5>
                    <button onClick={() => {setmodal_standard(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={e => { handleSubmit(e) }}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="control-label">Password</label>
                                    <input name="password" className="form-control" maxLength="30" value={formData.password} label="Password" placeholder="Password" type="text" onChange={e => updateFormData(e)}
                                        onBlur={e => updateFormData(e)} />
                                    <span className="text-danger">{passwordError ? 'Please add password' : ""}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="submit" color="primary" className="" >Submit</Button>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

        </div >
    );
}

export default ChangeVolunteerPassword;
