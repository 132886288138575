import { VolunteerAccessRoute } from "./roles";

export const decodeRole = () => {
    if (localStorage.getItem('user_token')) {
        return window.atob(localStorage.getItem('user_token'));
    } else {
        return '';
    }
}

export const isOuterMenuShowFromRoles = (roles) => {
    return roles.some(i => VolunteerAccessRoute.includes(i))
}