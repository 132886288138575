import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import API from '../../api';

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    CustomInput,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import ToastMessage from 'components/Common/toastar';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ContributionType } from "common/list";
import { useHistory, useParams } from "react-router-dom";

const optionGroup2 = ContributionType
const AddPlan = () => {
    const [formData, setFormData] = useState({
        plan_name: "",
        plans_amount: "",
        plans_validity_age: "",
        plans_age_min: "",
        plans_age_max: "",
        approx_year_payment: "",
        plans_payment_type: "One Time",
        approx_eligibility_day: ""
    });
    const { id } = useParams()
    const history = useHistory();

    useEffect(() => {
        if (id) {
            getSinglePlanDetail();
        }
    }, [])

    const updateFormData = event =>
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });

    const { plan_name, plans_amount, plans_validity_age } = formData;


    function getSinglePlanDetail() {
        let data = { plans_id: id }
        API.post('single_plans', data).then(res => {
            if (res.data.status === 1) {
                let data = res.data.data[0];
                data.plans_validity_age = data.plans_validity_age.toString();
                data.plans_age_max = data.plans_age_max.toString();
                data.plans_age_min = data.plans_age_min.toString();
                delete data.status_plans;
                delete data.plans_id;
                setFormData(data);
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })

        });
    }

    function handleSubmit(e) {
        e.preventDefault()
        if (id) {
            let data = {
                ...formData,
                plans_id: id
            }
            API.post('edit_plans', data).then(res => {
                if (res.data.status === 1) {
                    ToastMessage({ type: 'success', message: res.data.message })
                    history.push('/contribution-list')
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Internal Server Error' })

            });
        } else {
            let data = {
                ...formData,
            }
            API.post('create_plans', data).then(res => {
                if (res.data.status === 1) {
                    ToastMessage({ type: 'success', message: res.data.message })
                    history.push('/contribution-list')
                } else {
                    ToastMessage({ type: 'error', message: res.data.message })
                }
            }).catch(error => {
                ToastMessage({ type: 'error', message: 'Internal Server Error' })

            });
        }
    }

    function setAmountValue(event) {
        formData['plans_payment_type'] = event.target.value;
        setFormData({
            ...formData,
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{id ? 'Edit Contribution' : 'Add Contribution'}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Contribution" breadcrumbItem={id ? 'Edit Contribution' : 'Add Contribution'} />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>{id ? 'Edit Contribution Information' : 'Add Contribution Information'}</CardTitle>

                                    <AvForm onValidSubmit={e => { handleSubmit(e) }}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <AvField
                                                        name="plan_name"
                                                        label="Contribution Name"
                                                        placeholder="Contribution Name"
                                                        type="text"
                                                        maxLength="50"
                                                        value={formData.plan_name}
                                                        onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)}
                                                        errorMessage="Enter Contribution Name"
                                                        validate={{
                                                            required: { value: true },
                                                            maxLength: { value: 50, errorMessage: "Max 50 Characters." },
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3 templating-select select2-container">
                                                    <label className="control-label">Amount Type</label>
                                                    <select className="form-control" value={formData.plans_payment_type} onChange={setAmountValue}>
                                                        {optionGroup2.map((res) => {
                                                            return <option value={res.value} key={res.value}>{res.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <Label>Amount</Label>
                                                    <AvField
                                                        name="plans_amount"
                                                        type="text"
                                                        placeholder="Contribution Amount"
                                                        value={formData.plans_amount}
                                                        errorMessage="Enter Plan Amount"
                                                        maxLength="6"
                                                        onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)}
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: "^[#0-9]+$", errorMessage: "Invalid Value", },
                                                            maxLength: { value: 6, errorMessage: "Max 6 digits", },
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <Label>Valid Age</Label>
                                                    <AvField
                                                        name="plans_validity_age"
                                                        type="text"
                                                        maxLength="2"
                                                        value={formData.plans_validity_age}
                                                        placeholder="Valid Age"
                                                        onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)}
                                                        errorMessage="Enter Valid Age"
                                                        validate={{
                                                            required: { value: true },
                                                            maxLength: { value: 2, errorMessage: "Max 2 Numbers." },
                                                            pattern: { value: "^[#0-9]+$", errorMessage: "Invalid Value", },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <Label>Minimum age</Label>
                                                    <AvField
                                                        name="plans_age_min"
                                                        type="text"
                                                        maxLength="2"
                                                        value={formData.plans_age_min}
                                                        placeholder="Minimum age"
                                                        onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)}
                                                        errorMessage="Enter Plan Minimum age"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: "^[#0-9]+$", errorMessage: "Invalid Value", },
                                                            maxLength: { value: 2, errorMessage: "Max 2 Numbers." },

                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <Label>Maximum age</Label>
                                                    <AvField
                                                        name="plans_age_max"
                                                        type="text"
                                                        maxLength="2"
                                                        value={formData.plans_age_max}
                                                        placeholder="Maximum age"
                                                        onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)}
                                                        errorMessage="Enter Plan Maximum age"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: "^[#0-9]+$", errorMessage: "Invalid Value", },
                                                            maxLength: { value: 2, errorMessage: "Max 2 Numbers." },
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <Label>Approx Year Donation</Label>
                                                    <AvField
                                                        name="approx_year_payment"
                                                        type="text"
                                                        maxLength="50"
                                                        value={formData.approx_year_payment}
                                                        placeholder="Approx Year Donation"
                                                        onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)}
                                                        errorMessage="Enter Approx Year Donation"
                                                        validate={{
                                                            required: { value: true },
                                                            maxLength: { value: 50, errorMessage: "Max 50 Characters." },
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <Label>Approx eligibility day</Label>
                                                    <AvField
                                                        name="approx_eligibility_day"
                                                        type="text"
                                                        maxLength="4"
                                                        value={formData.approx_eligibility_day}
                                                        placeholder="Enter approx eligibility day"
                                                        onChange={e => updateFormData(e)}
                                                        onBlur={e => updateFormData(e)}
                                                        errorMessage="Enter approx eligibility day"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: "^[#0-9]+$", errorMessage: "Invalid Value", },
                                                            maxLength: { value: 4, errorMessage: "Max 4 Numbers." },

                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="d-flex flex-wrap gap-2">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className=""
                                            >Submit</Button>{" "}
                                            {/* <Button type="reset" color="secondary" className="">
                                                Cancel</Button> */}
                                        </div>
                                    </AvForm>
                                    {/* </form> */}
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddPlan
