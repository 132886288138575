import React from "react";
import API from '../../api';
import DatatableTables from "components/Common/Tables/DatatableTables";
import NotFound from "components/Common/notFound";
import LightBoxView from "components/Common/lightBoxView";
import { PaymentType, PaymentListFilters } from "common/list";
import Select from "react-select"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, CardTitle, Modal, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Flatpickr from "react-flatpickr"
import Lightbox from "react-image-lightbox"
import AddPayment from "./addPayment";
import "react-image-lightbox/style.css";
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";
import SweetAlert from "react-bootstrap-sweetalert"

export class PaymentList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            columns: null,
            error: null,
            selectedPayment: null,
            confirm_alert: false,
            isFits: false,
            currentAttachment: null,
            filteredPaymentType: null,
            allRecords: null,
            customDate: null,
            customEndDate: null,
            showDateRangePicker: false,
            paymentReceiptModal: false,
            paymentReciptData: null,
            payment_confirmation: false,
            selected_payment: null,
            selected_member: null,
            paymentUpdateModal: null,
            new_payment_date: null,
            new_transcation: null
        }
    }

    componentDidMount() {
        this.getAllPaymentDetails();
    }

    getAllPaymentDetails = () => {
        let columns = [
            { label: 'Member ID', field: 'member_id', sort: 'asc', width: 150 },
            { label: 'Pay ID', field: 'payment_id', sort: 'asc', width: 150 },
            { label: 'Date', field: 'payment_date', sort: 'asc', width: 150 },
            { label: 'TR ID', field: 'transcation_id', sort: 'asc', width: 150 },
            { label: 'Note', field: 'payment_note', sort: 'asc', width: 150 },
            { label: 'Type', field: 'payment_type', sort: 'asc', width: 200 },
            { label: 'Amount', field: 'amount', sort: 'asc', width: 130 },
            { label: 'Uploded Docs', field: 'payment_receipt', sort: 'asc', width: 5 },
            { label: 'Action', field: 'payment_delete', sort: 'asc', width: 5 },

        ]
        API.get('payment_list').then(res => {
            if (res.data.status === 1) {
                let rows = [];
                res.data.data.map(payment => {
                    rows.push({
                        ...payment,
                        payment_receipt: <img src={payment.payment_receipt} onClick={() => this.assignPhotostate(payment.payment_receipt)} height="75" width="75" />,
                        payment_delete:
                            <td>
                                <div className="uil-edit-alt d-flex margin-btn">
                                    <>{decodeRole() !== VOLUNTEER_ROLE ?
                                        <>
                                            <a onClick={() => this.setPaymentUpdateModal(payment)}><i className="fas fa-edit ml-4"></i></a>
                                            <a onClick={() => this.assignSelectedPayment(payment)}><i className="dripicons-trash ml-4"></i></a>
                                        </>
                                        : ""}</>
                                    <a onClick={() => this.getPaymentInvoice(payment.payment_id)}><i className="dripicons-cloud-download ml-4"></i></a>
                                </div>
                            </td>
                    })
                })
                this.setState(prevState => ({ ...prevState, rows: rows, allRecords: rows, columns: columns }))
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }

    getPaymentInvoice(id) {
        let data = { payment_id: id }
        API.post('payment_receipt', data).then(res => {
            if (res.data.status === 1) {
                this.setState(prevState => ({ ...prevState, paymentReceiptModal: true, paymentReciptData: res.data.data }))
            }
        });
    }



    assignPhotostate = imageUrl => {
        this.setState(prevState => ({ ...prevState, isFits: true, currentAttachment: imageUrl }))
    };

    remvovePhotostate = imageUrl => {
        this.setState(prevState => ({ ...prevState, isFits: false, currentAttachment: null }))
    };

    assignSelectedPayment = payment => {
        this.setState(prevState => ({ ...prevState, payment_confirmation: true, selected_member: payment.member_id, selected_payment: payment.payment_id }))
    };

    resetSelectedPayment() {
        this.setState(prevState => ({ ...prevState, payment_confirmation: false, selected_payment: null, selected_member: null }))
    };


    filteredPaymentData = value => {
        let selectedPayments = value ? value.map(res => res.value) : null;
        this.state.filteredPaymentType = selectedPayments;
        this.filteredSearchFromSelected();
    }

    filteredPaymentDateData = value => {
        this.setState(prevState => ({ ...prevState, showDateRangePicker: false }))
        this.state.customEndDate = new Date();
        let date = new Date();
        if (value.value == "Today") {
            this.state.customDate = new Date();
            this.state.customEndDate = false
        } else if (value.value == "Yesterday") {
            this.state.customDate = date.setDate(date.getDate() - 1);
            this.state.customEndDate = false;
        } else if (value.value == "Last 7 Days") {
            this.state.customDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
        } else if (value.value == "Last 30 Days") {
            this.state.customDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30);
        } else if (value.value == "Last Month") {
            this.state.customDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            this.state.customEndDate = new Date(date.getFullYear(), date.getMonth(), 0);
        } else if (value.value == "This Month") {
            this.state.customDate = new Date(date.getFullYear(), date.getMonth(), 1);
        } else if (value.value == "Custom Range") {
            this.setState(prevState => ({ ...prevState, showDateRangePicker: true }))
            return;
        } else {
            this.state.customDate = false;
        }
        this.filteredSearchFromSelected();
    }

    assignDateRange = e => {
        if (e.target.value) {
            let range = e.target.value.split('to')
            if (range.length >= 2) {
                this.state.customDate = range[0].trim();
                this.state.customEndDate = range[1].trim();
                this.filteredSearchFromSelected();
            }
        }
    }

    filteredSearchFromSelected = () => {
        let filteredPayment = [];
        if (!this.state.filteredPaymentType || !this.state.filteredPaymentType.length) {
            filteredPayment = this.state.allRecords
        }
        if (this.state.allRecords) {
            if (this.state.filteredPaymentType && this.state.filteredPaymentType.length) {
                filteredPayment = this.state.allRecords.filter(res =>
                    this.state.filteredPaymentType && this.state.filteredPaymentType.length ? this.state.filteredPaymentType.includes(res.payment_type) : false
                )
            }
            if (this.state.customDate && !this.state.customEndDate) {
                filteredPayment = filteredPayment.filter(res =>
                    this.state.customDate ? new Date(this.state.customDate).getTime() === new Date(res.payment_date).getTime() : false
                )
            }
            if (this.state.customDate && this.state.customEndDate) {
                filteredPayment = filteredPayment.filter(res =>
                    this.state.customDate ? this.checkDateInBetween(res.payment_date) : false
                )
            }
        } else {
            filteredPayment = this.state.allRecords;
        }
        this.setState(prevState => ({ ...prevState, rows: filteredPayment }))
    }

    checkDateInBetween = date => {
        var dateFrom = "02/05/2013";
        var dateTo = "02/09/2013";
        var dateCheck = date;

        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("-");

        var from = new Date(this.state.customDate).getTime()  // -1 because months are from 0 to 11
        var to = new Date(this.state.customEndDate).getTime();
        var check = new Date(Number(c[2]), parseInt(c[1]) - 1, Number(c[0])).getTime();
        return check > from && check < to
    }


    hidePaymentReceiptModal = () => {
        this.setState(prevState => ({ ...prevState, paymentReceiptModal: false }))
    }

    hidePaymentUpdateModal = () => {
        this.setState(prevState => ({ ...prevState, paymentUpdateModal: false, selectedPayment: null }))
    }

    setPaymentUpdateModal(payment) {
        this.state.new_payment_date = payment.payment_date;
        this.state.new_transcation = payment.transcation_id;
        this.state.selectedPayment = payment;
        this.setState(prevState => ({ ...prevState, paymentUpdateModal: true, selectedPayment: payment }))
    }

    updatePaymentDate() {
        let data = {
            member_id: this.state.selectedPayment.member_id,
            payment_date: this.state.new_payment_date,
            payment_id: this.state.selectedPayment.payment_id,
            transcation_id: this.state.new_transcation
        }
        API.post('payment_date_edit', data).then(res => {
            if (res.data.status === 1) {
                this.resetSelectedPayment();
                this.getAllPaymentDetails();
                this.hidePaymentUpdateModal();
            }
        });
    }

    Print = () => {
        var divContents = document.getElementById("printablediv").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write(divContents);
        a.document.close();
        a.print();
    }

    removePaymentInformation() {
        let data = {
            member_id: this.state.selected_member,
            payment_id: this.state.selected_payment
        }
        API.post('deleted_member_payment', data).then(res => {
            if (res.data.status === 1) {
                this.resetSelectedPayment();
                this.getAllPaymentDetails();
            }
        });
    }

    assigDateAndAge(e) {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currerntYear = new Date().getFullYear();
        this.state.new_payment_date = day + '-' + month + '-' + year;
    }

    convertDate(date) {
        if (date) {
            var parts = date.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    }

    render() {
        return (
            <div>

                <React.Fragment>
                    {this.state.isFits ?
                        <Lightbox
                            mainSrc={this.state.currentAttachment}
                            enableZoom={false}
                            onCloseRequest={() => { this.remvovePhotostate() }} />
                        : ""}

                    {this.state.payment_confirmation ? (
                        <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, delete it!" confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => { this.removePaymentInformation() }}
                            onCancel={() => {
                                this.resetSelectedPayment();
                            }
                            }>You won't be able to revert this!</SweetAlert>
                    ) : null}

                    {this.state.paymentUpdateModal ? (
                        <SweetAlert
                            showCancel
                            title="Update New Date"
                            cancelBtnBsStyle="danger"
                            confirmBtnBsStyle="success"
                            onConfirm={() => {
                                this.updatePaymentDate();
                            }}
                            onCancel={() => {
                                this.hidePaymentUpdateModal()
                            }}
                        >
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="control-label">New Date</label>
                                    <Flatpickr value={this.convertDate(this.state.selectedPayment.payment_date)} className="form-control d-block" placeholder="dd M,yyyy" name="birth_date"
                                        onChange={e => this.assigDateAndAge(e)}
                                        onBlur={e => this.assigDateAndAge(e)}
                                        options={{ altInput: true }} />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="control-label">Transaction Id</label>
                                    <input className="form-control" name="transcation_id" value={this.state.new_transcation}
                                        onChange={e => this.setState(prevState => ({ ...prevState, new_transcation: e.target.value }))}
                                        onBlur={e => this.setState(prevState => ({ ...prevState, new_transcation: e.target.value }))}
                                        placeholder="Transcation ID" type="texr" />
                                </div>
                            </div>
                        </SweetAlert>
                    ) : null}
                    <div className="page-content pb-0 mb-0">
                        <MetaTags>
                            <title>Donation List</title>
                        </MetaTags>
                        <Container fluid={true}>
                            <Breadcrumbs title="Donation" breadcrumbItem="Donation List" />
                            <Row>
                                <Modal
                                    size="lg"
                                    isOpen={this.state.paymentReceiptModal}
                                >
                                    <div className="modal-header">
                                        <h5
                                            className="modal-title mt-0"
                                            id="myExtraLargeModalLabel"
                                        >
                                            Payment Receipt
                            </h5>
                                        <button onClick={() => { this.hidePaymentReceiptModal() }}
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div id="printablediv">
                                            <div dangerouslySetInnerHTML={{ __html: this.state.paymentReciptData }} />;
                                        </div>
                                        <Button type="button" className="text-right print-btn" color="primary" onClick={() => { this.Print() }}>Print</Button>

                                    </div>
                                </Modal>
                                {/* <span dangerouslySetInnerHTML={{ __html: this.state.paymentReciptData }}> </span> */}

                                <Col lg={12}>
                                    <Card>

                                        <CardBody>
                                            {/* <CardTitle>Search</CardTitle> */}
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="control-label"> Donation Type </label>
                                                    <Select isMulti={true} onChange={e => { this.filteredPaymentData(e) }} options={PaymentType} classNamePrefix="select2-selection" />
                                                </div>

                                                <div className="col-md-4 mb-3">
                                                    <label className="control-label"> Select Date </label>
                                                    <Select onChange={e => { this.filteredPaymentDateData(e) }} options={PaymentListFilters} classNamePrefix="select2-selection" />
                                                </div>
                                                {this.state.showDateRangePicker ?
                                                    <div className="col-md-4 mb-3">
                                                        <label className="control-label"> Date Range </label>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="dd M,yyyy"
                                                            onBlur={e => this.assignDateRange(e)}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "Y-m-d"
                                                            }}
                                                        />
                                                    </div>
                                                    : ""}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <AddPayment onCloseData={(e) => this.getAllPaymentDetails()} />
                        </Container>
                    </div>
                </React.Fragment >
                <div>
                    {this.state.rows != null ?
                        <DatatableTables title={'Payment Details'} columns={this.state.columns} dataSource={this.state.rows} />
                        : <NotFound />
                    }
                </div>
            </div >
        )
    }
}


export default PaymentList;