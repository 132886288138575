import React from "react"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"

const NotFound = () => {
  
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4 text-center">No Data Available</CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NotFound
