import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button } from "reactstrap"
import Lightbox from "react-image-lightbox"
import ReceiptPDF from "pages/Member/ReceiptPDF";
import API from '../../api';
import SweetAlert from "react-bootstrap-sweetalert"
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";
import Flatpickr from "react-flatpickr"

const PaymentView = ({ payment, users }) => {
    let [imagePreview, setImagePreview] = useState({
        currentAttachment: null,
        isFits: false
    })
    let [paymentReceiptModal, setpaymentReceiptModal] = useState(false);
    let [paymentDeleteConfirmation, setPaymentDeleteConfirmation] = useState(false);
    let [paymentDeleteId, setPaymentDeleteId] = useState();

    let [paymentReciptData, setpaymentReciptData] = useState({})
    let [paymentArray, setPaymentArray] = useState(payment)
    let [selectedPayment, setSelectedPayment] = useState({})
    let [newTransacation, setNewTransaction] = useState()
    let [isUpdate, setUpdate] = useState()

    function assignPhotostate(imageUrl, folder_name) {
        setImagePreview({
            isFits: true,
            currentAttachment: imageUrl
        })
    }
    function removePhotostate() {
        setImagePreview({
            isFits: false,
            currentAttachment: null
        })
    }
    useEffect(() => {
    }, [])

    function Print() {
        var divContents = document.getElementById("printablediv").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write(divContents);
        a.document.close();
        a.print();
    }

    function getPaymentInvoice(id) {
        let data = { payment_id: id }
        API.post('payment_receipt', data).then(res => {
            if (res.data.status === 1) {
                setpaymentReceiptModal(true)
                setpaymentReciptData(res.data.data)
            }
        });
    }

    function showPaymentConfirmation(id) {
        setPaymentDeleteId(id);
        setPaymentDeleteConfirmation(true)
    }

    function removePaymentInformation() {
        let data = {
            member_id: users.member_id,
            payment_id: paymentDeleteId
        }
        API.post('deleted_member_payment', data).then(res => {
            if (res.data.status === 1) {
                let updated_payment = paymentArray.filter((res) => res.payment_id != paymentDeleteId);
                setPaymentDeleteConfirmation(false);
                setPaymentDeleteId(null);
                setPaymentArray(updated_payment)
            }
        });
    }


    function showReceipt(url) {

    }

    function updatePaymentDate() {
        let data = {
            member_id: users.member_id,
            payment_date: selectedPayment.payment_date,
            payment_id: selectedPayment.payment_id,
            transcation_id: newTransacation
        }
        API.post('payment_date_edit', data).then(res => {
            if (res.data.status === 1) {
                paymentArray[selectedPayment.index] = res.data.data;
                setPaymentArray(paymentArray);
                setUpdate(false);
            }
        });
    }


    function assigDateAndAge(e) {
        let date = new Date(e[0])
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currerntYear = new Date().getFullYear();
        setSelectedPayment({ ...selectedPayment, payment_date: day + '-' + month + '-' + year });
    }

    function convertDate(date) {
        if (date) {
            var parts = date.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
    }

    function assignUpdate(payment, index) {
        setUpdate(true);
        setSelectedPayment({ ...payment, index: index });
        setNewTransaction(payment.transcation_id)
    }

    return (
        <React.Fragment>
            {paymentDeleteConfirmation ? (
                <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, delete it!" confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => { removePaymentInformation() }}
                    onCancel={() => {
                        setPaymentDeleteConfirmation(false);
                        setPaymentDeleteId(null)
                    }
                    }>You won't be able to revert this!</SweetAlert>
            ) : null}

            {isUpdate ? (
                <SweetAlert
                    showCancel
                    title="Update New Date"
                    cancelBtnBsStyle="danger"
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        updatePaymentDate();
                    }}
                    onCancel={() => {
                        setUpdate(false)
                    }}
                >
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="control-label">New Date</label>
                            <Flatpickr value={convertDate(selectedPayment.payment_date)} className="form-control d-block" placeholder="dd M,yyyy" name="birth_date"
                                onChange={e => assigDateAndAge(e)}
                                onBlur={e => assigDateAndAge(e)}
                                options={{ altInput: true }} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="control-label">Transaction Id</label>
                            <input className="form-control" name="transcation_id" value={newTransacation}
                                onChange={e => setNewTransaction(e.target.value)}
                                onBlur={e => setNewTransaction(e.target.value)}
                                placeholder="Transcation ID" type="texr" />
                        </div>
                    </div>
                </SweetAlert>
            ) : null}
            {imagePreview.isFits ? (
                <Lightbox
                    mainSrc={imagePreview.currentAttachment}
                    enableZoom={false}
                    onCloseRequest={() => { removePhotostate() }} />
            ) : null}
            <div className="">
                <Modal
                    size="lg"
                    isOpen={paymentReceiptModal}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myExtraLargeModalLabel"
                        >
                            Payment Receipt
                            </h5>
                        <button onClick={() => { setpaymentReceiptModal(false) }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Button type="button" className="text-right" color="primary" onClick={() => { Print() }}>Print</Button>
                        <div id="printablediv">
                            <div dangerouslySetInnerHTML={{ __html: paymentReciptData }} />;
                                        </div>
                    </div>
                </Modal>

                <div className="container-fluid">
                    <Row>
                        <div className="head-text mb-4"> Donation Information </div>
                        <Col className="col-12">
                            {paymentArray.length ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Pay ID</th>
                                            <th>Transcation ID</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Type</th>
                                            <th>Note</th>
                                            <th>Uploaded Doc</th>
                                            <th>Receipt</th>
                                            {decodeRole() !== VOLUNTEER_ROLE ?
                                                <>
                                                    <th>Remove</th>
                                                    <th>Edit</th>
                                                </>
                                                : ""}
                                        </tr>
                                    </thead>
                                    {paymentArray.length ?
                                        paymentArray.map((res, i) => {
                                            return (
                                                <tbody key={i}>
                                                    <tr>
                                                        <td>{res.payment_id}</td>
                                                        <td>{res.transcation_id ? res.transcation_id : '-'}</td>
                                                        <td>{res.payment_date}</td>
                                                        <td>{res.amount}</td>
                                                        <td>{res.payment_type}</td>
                                                        <td>{res.payment_note}</td>
                                                        <td><img className="cursor-pointer" onClick={() => assignPhotostate(res.payment_receipt)} src={res.payment_receipt} width="50" height="50" /></td>
                                                        <td><div className="uil-edit-alt"> <a onClick={() => getPaymentInvoice(res.payment_id)}><i className="dripicons-cloud-download"></i></a></div></td>
                                                        {decodeRole() !== VOLUNTEER_ROLE ?
                                                            <>
                                                                <td><div className="uil-edit-alt"> <a onClick={() => showPaymentConfirmation(res.payment_id)}><i className="dripicons-trash"></i></a></div></td>
                                                                <td><div className="uil-edit-alt"> <a onClick={() => assignUpdate(res, i)}><i className="fas fa-edit"></i></a></div></td>
                                                            </>
                                                            : ""}
                                                    </tr>
                                                </tbody>)
                                        }) : ""}

                                </table>
                                :
                                <div className="not-found-message">Payment Data Not Available</div>
                            }
                        </Col>
                    </Row>
                </div>

            </div>
        </React.Fragment>
    )
}

export default PaymentView
