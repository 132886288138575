import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  Label,
  InputGroup
} from "reactstrap"
import { Link } from "react-router-dom"
import API from '../../api';
import Flatpickr from "react-flatpickr"

// Custom Scrollbar
import SimpleBar from "simplebar-react";
import ToastMessage from "components/Common/toastar";


//i18n
import { withTranslation } from "react-i18next"

const MangPatra = props => {
  const [menu, setMenu] = useState(false);
  let [mangPatrak, setMangPatrak] = useState(false);
  let [planMember, setPlanMember] = useState(false);
  let [allYears, setAllYears] = useState(false);
  let [formData, setFormData] = useState(false);
  let [setting, settingPatrak] = useState(false);
  let [selectedYear, setSelectedYear] = useState();
  let [total, setTotalAmt] = useState(false);
  let [roundOff, setRoundOff] = useState(0);
  let [initTotal, setInitTotal] = useState(0);
  let [amt, setAmt] = useState(0);
  let [modal_xlarge, setmodal_xlarge] = useState(false)
  let [modal_fullscreen, setmodal_fullscreen] = useState(false)

  let [modal_xlargeMangPatraView, setmodal_xlargeMangPatraView] = useState(false)
  let [modal_fullscreenMangPatraView, setmodal_fullscreenMangPatraView] = useState(false)

  let [userSetting, setUserSetting] = useState({
    setting1: null,
    setting2: null,
    setting3: null,
    setting4: null,
    setting5: null,
    setting6: null,
  })
  let [mangPatraViewForm, setMangPatraViewForm] = useState({
    issue_date: null,
    due_date: null,
    from_date: null,
    to_date: null,
    cont_amo: null,
    roun_amo: null,
  });
  let [mangPatraViewAPI, setMangPatraViewAPI] = useState(false)



  const toggle = () => {
    setMenu(!menu)
  }

  useEffect(() => {
    getMangPatrak();
  }, []);

  function getMangPatrak() {
    API.get('mang_partak_page', {}).then(res => {
      if (res.data && res.data.status) {
        setPlanMember(res.data.data.html2);
        setMangPatrak(res.data.data.html1);
        settingPatrak(res.data.data.mang_partak_setting);
        setAllYears(res.data.data.years_report);
        setUserSetting({
          setting1: res.data.data.mang_partak_setting[0].percentage,
          setting2: res.data.data.mang_partak_setting[1].percentage,
          setting3: res.data.data.mang_partak_setting[2].percentage,
          setting4: res.data.data.mang_partak_setting[3].par_member,
          setting5: res.data.data.mang_partak_setting[4].par_member,
          setting6: res.data.data.mang_partak_setting[5].par_member,
        })
      } else {
      }
    }).catch(error => {
    });
  }

  function onSelectedYearChange(e) {
    let index = allYears.findIndex(res => res.year === e.target.value);
    let obj = allYears[index];
    console.log(obj)
    setSelectedYear(obj);
    setInitTotal((Number(obj.divangat_total_payment_yearly) +
      Number((obj.divangat_total_payment_yearly * setting[0].percentage) / 100) +
      Number((obj.divangat_total_payment_yearly * setting[1].percentage) / 100) +
      Number((obj.divangat_total_payment_yearly * setting[2].percentage) / 100)));

    let usrAmt = (Number(obj.divangat_total_payment_yearly) +
      Number((obj.divangat_total_payment_yearly * setting[0].percentage) / 100) +
      Number((obj.divangat_total_payment_yearly * setting[1].percentage) / 100) +
      Number((obj.divangat_total_payment_yearly * setting[2].percentage) / 100)) / Number(obj.current_year_total_member);
    setAmt(usrAmt.toFixed(2));
    setTotalAmt((Number(usrAmt) + Number(setting[3].par_member) + Number(setting[4].par_member) + Number(setting[5].par_member)).toFixed(2));

  }

  function calulateTotal() {
    setMangPatraViewForm({
      ...mangPatraViewForm,
      cont_amo: amt,
      roun_amo: roundOff,
      divangat_user : selectedYear.divangat_user
    });
    mangPatraViewForm.cont_amo = amt;
    mangPatraViewForm.roun_amo = roundOff;
    mangPatraViewForm.divangat_user = selectedYear.divangat_user;
    API.post('mang_partak_view', mangPatraViewForm).then(res => {
      if (res.data && res.data.status) {
        setMangPatraViewAPI(res.data.data);
        setmodal_xlargeMangPatraView(true)
      } else {
        ToastMessage({ type: 'error', message: res.data.message })

      }
    }).catch(error => {
      ToastMessage({ type: 'error', message: 'Internal Server Error' })

    });
    console.log(mangPatraViewForm)
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen)
  }

  function tog_fullscreenMangPatraView() {
    setmodal_fullscreenMangPatraView(!modal_fullscreenMangPatraView)
  }

  function updateSetting(e, val, per) {
    if (per && val > 100) {
      ToastMessage({ type: 'error', message: 'Invalid value' })
      return;
    }

    let data = {
      "setting_id": e.setting_id,
      "percentage": per ? val : e.percentage,
      "par_member": per ? e.par_member : val
    }
    API.post('mang_partak_update', data).then(res => {
      if (res.data && res.data.status) {
        ToastMessage({ type: 'success', message: res.data.message })
      } else {
        ToastMessage({ type: 'error', message: res.data.message })

      }
    }).catch(error => {
      ToastMessage({ type: 'error', message: 'Internal Server Error' })

    });
  }

  function convertDate(date) {
    if (date) {
      var parts = date.split('-');
      return new Date(parts[2], parts[1] - 1, parts[0]);
    }
  }

  function updateMangPtraViewForm(e, name) {
    let type;
    switch (name) {
      case 1:
        type = 'issue_date';
        break;
      case 2:
        type = 'due_date';
        break;
      case 3:
        type = 'from_date';
        break;
      case 4:
        type = 'to_date';
        break;
      default:
        break;
    }
    let date = new Date(e[0])
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    setMangPatraViewForm({
      ...mangPatraViewForm,
      [type]: day + '-' + month + '-' + year,
    });
  }

  function closeModalAndGetData() {
    setmodal_xlarge(false);
    getMangPatrak()
  }

  function closeModalAndGetDataMangPatraView() {
    setmodal_xlargeMangPatraView(false);
  }

  function Print() {
    var divContents = document.getElementById("printablediv").innerHTML;
    var a = window.open('', '', 'height=500, width=500');
    a.document.write(divContents);
    a.document.close();
    a.print();
}

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>MangPatra | UmaChattra</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Mang Patra</h6>

              </Col>
            </Row>

            <Modal size="lg" isOpen={modal_xlarge} toggle={() => { tog_fullscreen() }}>
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">Mang Patra Settings </h5>
                <button onClick={() => { closeModalAndGetData() }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12 mb-3 ">
                  <div className="mb-3">
                    {setting ?
                      <div className="d-flex w-100">
                        <div>
                          <div className="row">
                            <div className="mangpatra-flex">
                              <div className="col-md-8">
                                <div className="font-size-val"> <span>1.)</span> {setting[0].setting_name} %</div>
                              </div>
                              <div className="font-size-val"><input type="number" onChange={e => setUserSetting({ setting1: e.target.value })}
                                onBlur={e => setUserSetting({ setting1: e.target.value })} className="form-control" id="setting_1" value={userSetting.setting1} name="setting_1" max="100" /></div>
                              <Button onClick={() => { updateSetting(setting[0], userSetting.setting1, true) }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Update </Button>

                            </div>
                            <div className="mangpatra-flex">
                              <div className="col-md-8">
                                <div className="font-size-val"> <span>2.)</span> {setting[1].setting_name}  %</div>
                              </div>
                              <div className="font-size-val"><input type="number" onChange={e => setUserSetting({ setting2: e.target.value })}
                                onBlur={e => setUserSetting({ setting2: e.target.value })} className="form-control" value={userSetting.setting2} name="setting_2" id="setting_2" max="100" /></div>
                              <Button onClick={() => { updateSetting(setting[1], userSetting.setting2, true) }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Update </Button>                            </div>
                            <div className="mangpatra-flex">
                              <div className="col-md-8">
                                <div className="font-size-val"> <span>3.)</span> {setting[2].setting_name}  % </div>
                              </div>
                              <div className="font-size-val"><input type="number" onChange={e => setUserSetting({ setting3: e.target.value })}
                                onBlur={e => setUserSetting({ setting3: e.target.value })} className="form-control" value={userSetting.setting3} name="setting_3" id="setting_2" max="100" /></div>
                              <Button onClick={() => { updateSetting(setting[2], userSetting.setting3, true) }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Update </Button>                            </div>
                          </div>

                          <div className="row">
                            <div className="mangpatra-flex">
                              <div className="col-md-8">
                                <div className="font-size-val"> <span>4.)</span> {setting[3].setting_name}</div>
                              </div>
                              <div className="font-size-val">
                                <input type="number" onChange={e => setUserSetting({ setting4: e.target.value })}
                                  onBlur={e => setUserSetting({ setting4: e.target.value })} className="form-control" value={userSetting.setting4} name="setting_4" id="setting_4" max="100" /></div>
                              <Button onClick={() => { updateSetting(setting[3], userSetting.setting4, false) }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Update </Button>
                            </div>
                            <div className="mangpatra-flex">
                              <div className="col-md-8">

                                <div className="font-size-val"> <span>5.)</span> {setting[4].setting_name}</div>
                              </div>
                              <div className="font-size-val"><input type="number" onChange={e => setUserSetting({ setting5: e.target.value })}
                                onBlur={e => setUserSetting({ setting5: e.target.value })} className="form-control" value={userSetting.setting5} name="setting_4" id="setting_4" max="100" /></div>
                              <Button onClick={() => { updateSetting(setting[4], userSetting.setting5, false) }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Update </Button></div>
                            <div className="mangpatra-flex">
                              <div className="col-md-8">

                                <div className="font-size-val"> <span>6.)</span> {setting[5].setting_name}</div>
                              </div>
                              <div className="font-size-val"><input type="number" onChange={e => setUserSetting({ setting6: e.target.value })}
                                onBlur={e => setUserSetting({ setting6: e.target.value })} className="form-control" value={userSetting.setting6} name="setting_6" id="setting_6" max="100" /></div>
                              <Button onClick={() => { updateSetting(setting[5], userSetting.setting6, true) }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Update </Button></div>

                          </div>


                        </div>
                      </div>
                      : ""}
                  </div>
                </div>
              </div>
            </Modal>

            <Modal size="lg" isOpen={modal_xlargeMangPatraView} toggle={() => { tog_fullscreenMangPatraView() }}>
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">Mang Patra View </h5>
                <button onClick={() => { closeModalAndGetDataMangPatraView() }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12 mb-3 ">
                  <div className="mb-3">
                    <div id="printablediv">
                      <div dangerouslySetInnerHTML={{ __html: mangPatraViewAPI }} />;
                    </div>
                  </div>
                  <Button type="button" className="text-right print-btn" color="primary" onClick={() => { Print() }}>Print</Button>

                </div>
              </div>
            </Modal>

            <Row>
              <div>
                <Button onClick={() => { setmodal_xlarge(true) }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">Mang Patra Setting </Button>
              </div>
            </Row>
            <Row>
              <div className="counter home-section section-1 container">
                <div className="row">
                  <div dangerouslySetInnerHTML={{ __html: planMember }}></div>
                </div>
                <div className="row">
                  <div dangerouslySetInnerHTML={{ __html: mangPatrak }}></div>
                </div>
                {allYears.length ?
                  <div className="row">
                    <div className="d-flex justify-content-center">
                      <div className="col-md-3 mb-3 templating-select select2-container">
                        <label className="control-label">Select Year</label>
                        <select className="form-control" onChange={e => onSelectedYearChange(e)}>
                          <option>Select Any Year</option>
                          {allYears.map((res, i) => {
                            return <option key={i} value={res.year}>{res.year}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  : ""}
                {selectedYear ?
                  <div className="d-flex justify-content-center ">
                    <div className="w-50">
                      <div className="row">
                        <div className="mangpatra-flex">
                          <div className="font-size-val"> <span>1.)</span> Year Divangat Amount</div>
                          <div className="font-size-val">{selectedYear.divangat_total_payment_yearly}</div>
                        </div>
                      </div>
                      {setting.length ?
                        <div>
                          <div className="row">
                            <div className="mangpatra-flex">
                              <div className="font-size-val"> <span>2.)</span> {setting[0].setting_name} <span>({setting[0].percentage} %)</span></div>
                              <div className="font-size-val">{(selectedYear.divangat_total_payment_yearly * setting[0].percentage) / 100}</div>
                            </div>
                            <div className="mangpatra-flex">
                              <div className="font-size-val"> <span>3.)</span> {setting[1].setting_name} <span>({setting[1].percentage} %)</span></div>
                              <div className="font-size-val">{(selectedYear.divangat_total_payment_yearly * setting[1].percentage) / 100}</div>
                            </div>
                            <div className="mangpatra-flex">
                              <div className="font-size-val"> <span>4.)</span> {setting[2].setting_name} <span>({setting[2].percentage} %)</span></div>
                              <div className="font-size-val">{(selectedYear.divangat_total_payment_yearly * setting[2].percentage) / 100}</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="mangpatra-flex">
                              <div className="font-size-val"> <span>Total </span></div>
                              <div className="font-size-val"> {initTotal}</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="mangpatra-flex">
                              <div className="font-size-val"> <span>5.)</span>Member Register ({selectedYear.current_year_total_member})</div>
                              <div className="font-size-val"> {amt}</div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="mangpatra-flex">
                              <div className="font-size-val"> <span>6.)</span> {setting[3].setting_name}</div>
                              <div className="font-size-val">{setting[3].par_member}</div>
                            </div>
                            <div className="mangpatra-flex">
                              <div className="font-size-val"> <span>7.)</span> {setting[4].setting_name}</div>
                              <div className="font-size-val">{setting[4].par_member}</div>
                            </div>
                            <div className="mangpatra-flex">
                              <div className="font-size-val"> <span>8.)</span> {setting[5].setting_name}</div>
                              <div className="font-size-val">{setting[5].par_member}</div>
                            </div>
                          </div>
                          <div className="mangpatra-flex">
                            <div className="font-size-val"> Total Amount</div>
                            <div className="font-size-val">{total}</div>
                          </div>
                          <div className="row">
                            <div className="">
                              <div className="mb-3 mangpatra-flex">
                                <div className="">
                                  <div className="font-size-val">9.) RoundOff</div>
                                </div>
                                <div>
                                  <input name="name" className="form-control form-right" maxLength="50" value={roundOff} label="Member Name" placeholder="Member Name" type="text" onChange={e => setRoundOff(e.target.value)}
                                    onBlur={e => setRoundOff(e.target.value)} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        : ""}

                      <div className="row">
                        <div className="col-md-3">
                          <div className="mb-3">
                            <Label>Issue Date</Label>
                            <InputGroup>
                              <Flatpickr value={convertDate(mangPatraViewForm.issue_date)} className="form-control d-block" placeholder="dd M,yyyy" name="issue_date"
                                onChange={e => updateMangPtraViewForm(e, 1)}
                                onBlur={e => updateMangPtraViewForm(e, 1)}
                                options={{ altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d" }} />
                            </InputGroup>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <Label>Due Date</Label>
                            <InputGroup>
                              <Flatpickr value={convertDate(mangPatraViewForm.due_date)} className="form-control d-block" placeholder="dd M,yyyy" name="due_date"
                                onChange={e => updateMangPtraViewForm(e, 2)}
                                onBlur={e => updateMangPtraViewForm(e, 2)}
                                options={{ altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d" }} />
                            </InputGroup>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <Label>From Date</Label>
                            <InputGroup>
                              <Flatpickr value={convertDate(mangPatraViewForm.from_date)} className="form-control d-block" placeholder="dd M,yyyy" name="from_date"
                                onChange={e => updateMangPtraViewForm(e, 3)}
                                onBlur={e => updateMangPtraViewForm(e, 3)}
                                options={{ altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d" }} />
                            </InputGroup>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <Label>To Date</Label>
                            <InputGroup>
                              <Flatpickr value={convertDate(mangPatraViewForm.to_date)} className="form-control d-block" placeholder="dd M,yyyy" name="to_date"
                                onChange={e => updateMangPtraViewForm(e, 4)}
                                onBlur={e => updateMangPtraViewForm(e, 4)}
                                options={{ altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d" }} />
                            </InputGroup>
                          </div>
                        </div>
                        <Button onClick={() => { calulateTotal() }} color="success" className="btn btn-success mt-3 mb-3 mt-lg-0">View MangPatra </Button>
                      </div>
                    </div>
                  </div>
                  : ""}
              </div>

            </Row>

          </div>
        </Container>
      </div >

    </React.Fragment >
  )
}

MangPatra.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(MangPatra)
