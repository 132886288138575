import React from "react";
import API from '../../api';
import DatatableTables from "components/Common/Tables/DatatableTables";
import SweetAlert from "react-bootstrap-sweetalert"
import ToastMessage from 'components/Common/toastar';
import { Link } from 'react-router-dom';
import NotFound from "components/Common/notFound";
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button } from "reactstrap"

export class AllMemberList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            columns: null,
            rowsForVerified: null,
            columnsForVerified: null,
            error: null,
            selectedMember: null,
            selectedMemberVerified: null,
            confirm_alert_verified: false,
            confirm_alert: false,
            type: props.match.params.id,
            isMemberCertificateShow: false,
            isMemberCertificateContent: null,
        }
    }

    componentDidMount() {
        this.getAllMember();
    }

    deleteMember(index) {
        this.setState(prevState => ({ ...prevState, confirm_alert: true, selectedMember: index }))
    }


    removeMemberFromMember(isVerified) {
        let data = {
            member_id: isVerified ? this.state.selectedMemberVerified : this.state.selectedMember
        }
        API.post('delete_member', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message })
                if (isVerified) {
                    this.setState(prevState => ({ ...prevState, confirm_alert_verified: false, selectedMemberVerified: null }))
                    this.getAllMembersVerified();
                } else {
                    this.setState(prevState => ({ ...prevState, confirm_alert: false, selectedMember: null }));
                    this.getAllMembersUnverified();
                }
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    getMemberCertificate(member_id) {
        let data = {
            member_id: member_id
        }
        API.post('member_certificate', data).then(res => {
            if (res.data.status === 0) {
                this.setState(prevState => ({ ...prevState, isMemberCertificateShow: true, isMemberCertificateContent: res.data.data }))
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }


    getAllMember() {
        let columns = [
            { label: 'Member ID', field: 'member_id', sort: 'asc', width: 150 },
            { label: 'Name', field: 'member_name', sort: 'asc', width: 150 },
            { label: 'Mobile', field: 'mobile_no_1', sort: 'asc', width: 150 },
            { label: 'Pin', field: 'pin_code', sort: 'asc', width: 200 },
            { label: 'State', field: 'state', sort: 'asc', width: 200 },
            { label: 'District', field: 'district', sort: 'asc', width: 200 },
            { label: 'City', field: 'city', sort: 'asc', width: 200 },
            { label: 'Amount', field: 'payment_amount', sort: 'asc', width: 130 },
            { label: 'First Payment Day', field: 'first_payment', sort: 'asc', width: 130 },
            { label: 'Eligibility', field: 'eligibility', sort: 'asc', width: 130 }, 
            { label: 'Action', field: 'action', sort: 'asc', width: 150 }
        ]
        API.get('list_all_member').then(res => {
            if (res.data.status === 1) {
                let rows = [];
                res.data.data.map(member => {
                    rows.push({
                        ...member,
                        member_name: member.name + ' ' + member.father_name + ' ' + member.surname_name,
                        payment_amount: member.plan_info.plans_amount,
                        first_payment:  member.Payments.length ? member.Payments[0].payment_date : '-',
                        payment_type: member.plan_info.plans_payment_type,
                        eligibility : member.eligibility ? 'Yes' : 'No',

                        action:
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {member.status_verified ?
                                    <div className="uil-download-alt ml-4" onClick={() => this.getMemberCertificate(member.member_id)}><i className="ti-download"></i></div>
                                    : ""}
                                <div className="uil-edit-alt"> <Link to={`/add-member/${member.member_id}`}><i className="ti-pencil-alt"></i></Link>
                                </div>
                                <div className="uil-edit-alt ml-4"> <Link to={`/view-member/${member.member_id}`}><i className="ti-receipt"></i></Link>
                                </div>
                                {decodeRole() !== VOLUNTEER_ROLE ?
                                    <div className="uil-trash-alt ml-4" onClick={() => this.deleteMember(member.member_id)}><i className="ti-trash"></i></div>
                                    : ""}
                            </div>
                    })
                });
                this.setState(prevState => ({ ...prevState, rowsForVerified: rows, columnsForVerified: columns }))
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }

    hideModal() {
        this.setState(prevState => ({ ...prevState, isMemberCertificateShow: false, isMemberCertificateContent: null }))
    }

    Print(){
        var divContents = document.getElementById("printablediv").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write(divContents);
        a.document.close();
        a.print();
    }

    render() {
        return (
            <div>
                <Modal
                    size="lg"
                    isOpen={this.state.isMemberCertificateShow}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myExtraLargeModalLabel"
                        >
                            Member Certificate
                            </h5>
                        <button onClick={() => { this.hideModal() }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div id="printablediv">
                            <div dangerouslySetInnerHTML={{ __html: this.state.isMemberCertificateContent }} />
                        </div>
                        <Button type="button" className="text-right print-btn" color="primary" onClick={() => { this.Print() }}>Print</Button>

                    </div>
                </Modal>
                <div >
                    {this.state.confirm_alert ? (
                        <SweetAlert title="Are you sure?" warning showCancel confirmButtonText="Yes, delete it!" confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => { this.removeMemberFromMember(false) }}
                            onCancel={() => this.setState(prevState => ({ ...prevState, confirm_alert: false }))
                            }>You won't be able to revert this!</SweetAlert>
                    ) : null}

                    {
                        this.state.rowsForVerified != null ?
                            <DatatableTables title={'All Member Details'} columns={this.state.columnsForVerified} dataSource={this.state.rowsForVerified} />
                            : <NotFound />}

                </div>

            </div>
        )
    }
}


export default AllMemberList;