import React from "react";
import API from '../../api';
import DatatableTables from "components/Common/Tables/DatatableTables";
import SweetAlert from "react-bootstrap-sweetalert"
import ToastMessage from 'components/Common/toastar';
import { Link } from 'react-router-dom';
import { decodeRole } from "common/decodedRole";
import { VOLUNTEER_ROLE } from "common/roles";
import ChangePlanStatus from "./changePlanStatus";

export class PlanList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            rows: null,
            columns: null,
            error: null,
            selectedPlan: null,
            confirm_alert: false
        }
    }



    componentDidMount() {
        this.getAllPlans();
    }

    componentDidUpdate() {
        console.log('Did Update')
    }

    deletePost(index) {
      
    }

    removeOrHideModal() {
        this.setState(prevState => ({ ...prevState, confirm_alert: false }))
    }

    removePlanForList() {
        let data = {
            plans_id: this.state.selectedPlan
        }
        API.post('delete_plans', data).then(res => {
            if (res.data.status === 1) {
                ToastMessage({ type: 'success', message: res.data.message })
                this.setState(prevState => ({ ...prevState, confirm_alert: false, selectedPlan: null }))
                this.getAllPlans();
            } else {
                ToastMessage({ type: 'error', message: res.data.message })
            }
        }).catch(error => {
            ToastMessage({ type: 'error', message: 'Internal Server Error' })
        });
    }

    getAllPlans() {
        let columns = [
            { label: 'Contribution Name', field: 'plan_name', sort: 'asc', width: 150 },
            { label: 'Amount', field: 'plans_amount', sort: 'asc', width: 80 },
            { label: 'Payment Type', field: 'plans_payment_type', sort: 'asc', width: 150 },
            { label: 'Year Payment', field: 'approx_year_payment', sort: 'asc', width: 200 },
            { label: 'Plan Age Min', field: 'plans_age_min', sort: 'asc', width: 150 },
            { label: 'Plan Age Max', field: 'plans_age_max', sort: 'asc', width: 150 },
            { label: 'Validity Age', field: 'plans_validity_age', sort: 'asc', width: 150 },
            { label: 'Approx eligibility day', field: 'approx_eligibility_day', sort: 'asc', width: 150 },
            { label: 'Status', field: 'status', sort: 'asc', width: 150 },
            { label: 'Action', field: 'action', sort: 'asc', width: 150 }
        ]
        API.get('all_plans').then(res => {
            if (res.data.status === 1) {
                let rows = [];
                res.data.data.map(plan => {
                    rows.push({
                        ...plan,
                        status: <div><ChangePlanStatus plan_id={plan.plans_id} status={plan.status_plans} /></div>,
                        action:
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="uil-edit-alt"> <Link to={`/add-contribution/${plan.plans_id}`}><i className="ti-pencil-alt"></i></Link></div>
                                <div className="uil-trash-alt ml-4" onClick={() =>  this.setState((prevState) => { return { ...prevState, confirm_alert: true, selectedPlan: plan } })}> <i className="ti-trash"></i></div>
                            </div>
                    })
                })
                if (decodeRole() === VOLUNTEER_ROLE) {
                    columns.pop()
                    columns.pop()
                }
                this.setState({ rows: rows, columns: columns })
            }
        }).catch(error => {
            this.setState({ error: 'Internal Server Error' })
        });
    }

    render() {
        return (
            <div>
                Plan List
                {/* <AddLanguage onSelectLanguage={this.handleLanguage}/> */}
                <div >
                    {this.state.confirm_alert ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmButtonText="Yes, delete it!"
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                this.removePlanForList()
                            }}
                            onCancel={() => this.removeOrHideModal()}
                        >
                            You won't be able to revert this!
                            </SweetAlert>
                    ) : null}
                    {this.state.rows != null ?
                        <DatatableTables title={'Contribution Details'} columns={this.state.columns} dataSource={this.state.rows} />
                        : ''}
                </div>

            </div>
        )
    }
}


export default PlanList;