import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"


// Dashboard
import Dashboard from "../pages/Dashboard/index"
import AddPlan from "pages/Plan/addPlan";
import { PlanList } from "pages/Plan/planList";
import AddMember from "pages/Member/addMember";
import { MemberList } from "pages/Member/memberList";
import { ViewMember } from "pages/Member/viewMember";
import { PaymentList } from "pages/Payment/paymentList";
import AddVolunteer from "pages/Volunteer/addVolunteer";
import { VolunteerList } from "pages/Volunteer/volunteerList";
import AddDivangat from "pages/Divangat/addDivangat";
import { DivangatList } from "pages/Divangat/divangatList";
import { ContactList } from "pages/Contact/contactList";
import { UsersList } from "pages/Users/userList";
import { AllMemberList } from "pages/Member/allMemberList";
import { PendingPaymentList } from "pages/Payment/pendingPaymentList";
import { GetReport } from "pages/Member/getReport";
import paymentResponse from "pages/Member/paymentResponse";
import MangPatra from "pages/MangPatra/mang-patra";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/paymentresponse/:id", component: paymentResponse },

  //Plan
  { path: "/add-contribution", component: AddPlan },
  { path: "/add-contribution/:id", component: AddPlan },
  { path: "/contribution-list", component: PlanList },

  { path: "/add-member", component: AddMember },
  { path: "/member-not-verified-list/:id", component: MemberList },
  { path: "/member-list/:id", component: MemberList },
  { path: "/all-member-list", component: AllMemberList },

  { path: "/add-member/:id", component: AddMember },
  { path: "/view-member/:id", component: ViewMember },
  { path: "/get-report", component: GetReport },

  { path: "/payment-list", component: PaymentList },
  { path: "/pending-payment-list", component: PendingPaymentList },

  { path: "/volunteer-list", component: VolunteerList },
  { path: "/add-volunteer", component: AddVolunteer },
  { path: "/edit-volunteer/:id", component: AddVolunteer },

  { path: "/add-divangat", component: AddDivangat },
  { path: "/add-divangat/:id", component: AddDivangat },
  { path: "/divangat-not-verified-list/:id", component: DivangatList },
  { path: "/divangat-list/:id", component: DivangatList },
  { path: "/mang-patra", component: MangPatra },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/contact-list", component: ContactList },
  { path: "/users-list", component: UsersList },


  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },


]

export { userRoutes, authRoutes }
