export const PaymentType = [
    { label: "RTGS", value: "RTGS" },
    { label: "NEFT", value: "NEFT" },
    { label: "IMPS", value: "IMPS" },
    { label: "UPI", value: "UPI" },
    { label: "Check", value: "Check" },
    { label: "Cash", value: "Cash" },
    { label: "Cash Credit", value: "Cash Credit" },
    { label: "Demand Draft", value: "Demand Draft" }
]
export const BloodGroup = [
    { label: "N/A", value: "" },
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" }
]
export const Gender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
]
export const MaritalStatus = [
    { label: "Unmarried", value: "Unmarried" },
    { label: "Married", value: "Married" },
    { label: "Widowed", value: "Widowed" },
    { label: "Seprated", value: "Seprated" },
    { label: "Widower", value: "Widower" },
    { label: "Divorced", value: "Divorced" },
]

export const Cast = [
    { label: "Kadva", value: "Kadva" },
    { label: "Leuva", value: "Leuva" },
    { label: "Other", value: "Other" }
]

export const RelationList = [
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Grand-Father", value: "Grand-Father" },
    { label: "Grand-Mother", value: "Grand-Mother" },
    { label: "Sister", value: "Sister" },
    { label: "Son", value: "Son" },
    { label: "Daughter", value: "Daughter" },
    { label: "Husband", value: "Husband" },
    { label: "Wife", value: "Wife" },
    { label: "Father-in-law", value: "Father-in-law" },
    { label: "Mother-in-law", value: "Mother-in-law" },
    { label: "Brother-in-law", value: "Brother-in-law" },
    { label: "Sister-in-law", value: "Sister-in-law" }
]
export const ContributionType = [
    { label: "One Time", value: "One Time" },
    { label: "Monthly", value: "Monthly" },
    { label: "Quaterly", value: "Quaterly" },
    { label: "Half-Year", value: "Half-Year" },
    { label: "Yearly", value: "Yearly" },
]

export const OccupationType = [
    { label: "Bussiness", value: "Bussiness" },
    { label: "Private Service", value: "Private Service" },
    { label: "Govt Service", value: "Govt Service" },
    { label: "Farmer", value: "Farmer" },
    { label: "House-Wife", value: "House-Wife" },
    { label: "Unemployeed ", value: "Unemployeed " },
    { label: "Other", value: "Other" },
]

export const PaymentListFilters = [
    { label: "All", value: "All" },
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "Last 7 Days", value: "Last 7 Days" },
    { label: "Last 30 Days", value: "Last 30 Days" },
    { label: "This Month", value: "This Month" },
    { label: "Last Month", value: "Last Month" },
    { label: "Custom Range", value: "Custom Range" },
]

